// // import React, { useState, useEffect, useCallback } from "react";
// // import { toast } from "react-toastify";
// // import { useLocation } from "react-router-dom";
// // import {
// //   getPrivacyPolicies,
// //   deletePrivacyPolicy,
// //   makePrivacyPolicyLive,
// //   getAllPermission,
// // } from "../../apis";
// // import { bindActionCreators } from "redux";
// // import { connect } from "react-redux";
// // import actions from "../../actions";
// // import Pagination from "../../components/Pagination/Pagination";
// // import AddEdit from "./AddEdit";

// // const PrivacyPolicies = ({
// //   logoutRequest,
// //   startLoadingRequest,
// //   stopLoadingRequest,
// // }) => {
// //   const [data, setData] = useState({});
// //   const { pathname, search } = useLocation();
// //   const fetch = useCallback(async () => {
// //     try {
// //       const permission = await getAllPermission()
// //       console.log(permission)
// //       const apiResponse = await getPrivacyPolicies(search);

// //       if (apiResponse?.data?.data?.privacyPolicies?.length > 0) {
// //         setData({ ...apiResponse?.data?.data });
// //       } else {
// //         setData({});
// //       }
// //       stopLoadingRequest();
// //     } catch (error) {
// //       if (error?.response?.status === 401) {
// //         toast.error(error.response.data.message);
// //         logoutRequest();
// //       } else if (error?.response?.status === 403) {
// //         toast.error(error.response.data.message);
// //       }
// //       stopLoadingRequest();
// //     }
// //   }, [data, search, pathname]);

// //   useEffect(() => {
// //     startLoadingRequest();

// //     fetch();
// //   }, [pathname, search]);
// //   const activateDeactivatePrivacyPolicyHandler = useCallback(
// //     async (id, isActive) => {
// //       try {
// //         startLoadingRequest();
// //         await deletePrivacyPolicy(id);
// //         toast.success(
// //           !isActive
// //             ? "Privacy policy activated successfully"
// //             : "Privacy policy deactivated successfully"
// //         );
// //         fetch();
// //       } catch (error) {
// //         if (error?.response?.status === 401) {
// //           toast.error(error.response.data.message);
// //           logoutRequest();
// //         } else if (error?.response?.status === 403) {
// //           toast.error(error.response.data.message);
// //         }
// //         stopLoadingRequest();
// //       }
// //     },
// //     []
// //   );
// //   const makePrivacyPolicyLiveHandler = useCallback(async (id) => {
// //     try {
// //       startLoadingRequest();
// //       await makePrivacyPolicyLive(id);
// //       toast.success("Privacy policy is now live");
// //       fetch();
// //     } catch (error) {
// //       toast.error(error.response.data.message);
// //       if (error?.response?.status === 401) {
// //         toast.error(error.response.data.message);
// //         logoutRequest();
// //       }
// //       stopLoadingRequest();
// //     }
// //   }, []);
// //   return (
// //     <div className="section">
// //       <h1>Privacy Policies</h1>
// //       <div className="wrapper">
// //         <div className="table-wrapper">
// //           <div className="table-header">
// //             <AddEdit fetch={fetch} />
// //           </div>
// //           <div className="table-container">
// //             <table>
// //               <thead>
// //                 <th>#</th>
// //                 <th>Title</th>
// //                 <th>Caption</th>
// //                 <th>Active/Inactive</th>
// //                 <th>Created at</th>
// //                 <th>Actions</th>
// //               </thead>
// //               <tbody>
// //                 {data?.privacyPolicies?.map((item, index) => (
// //                   <tr key={item._id}>
// //                     <td>{index + 1}</td>
// //                     <td>{item.title}</td>
// //                     <td>{item.caption}</td>
// //                     <td>{item.isActive ? "Active" : "Inactive"}</td>
// //                     <td>{item.createdAt}</td>
// //                     <td>
// //                       <div className="table-actions">
// //                         {item?.isActive && (
// //                           <>
// //                             <AddEdit
// //                               type={`TnE-${item._id}`}
// //                               data={item}
// //                               fetch={fetch}
// //                             />
// //                             {/* {!item.isLive && (
// //                               <>
// //                                 <button
// //                                   className="success"
// //                                   onClick={() =>
// //                                     makeTipAndTrickLiveHandler(item._id)
// //                                   }
// //                                 >
// //                                   Publish
// //                                 </button>
// //                               </>
// //                             )} */}
// //                           </>
// //                         )}
// //                         <button
// //                           className={item.isActive ? "danger" : "success"}
// //                           onClick={() =>
// //                             activateDeactivatePrivacyPolicyHandler(
// //                               item._id,
// //                               item.isActive
// //                             )
// //                           }
// //                         >
// //                           {item.isActive ? "Deactivate" : "Activate"}
// //                         </button>
// //                       </div>
// //                     </td>
// //                   </tr>
// //                 ))}
// //               </tbody>
// //             </table>
// //           </div>
// //           <Pagination pageInfo={data.pageInfo} />
// //         </div>
// //       </div>
// //     </div>
// //   );
// // };

// // const mapDispatchToProps = (dispatch) =>
// //   bindActionCreators(
// //     {
// //       logoutRequest: actions.auth.logout,
// //       stopLoadingRequest: actions.loader.stopLoading,
// //       startLoadingRequest: actions.loader.startLoading,
// //     },
// //     dispatch
// //   );

// // export default connect(null, mapDispatchToProps)(PrivacyPolicies);

// import React, { useState, useEffect, useCallback } from "react";
// import { toast } from "react-toastify";
// import { useLocation } from "react-router-dom";
// import {
//   getPrivacyPolicies,
//   deletePrivacyPolicy,
//   makePrivacyPolicyLive,
//   updatePrivacyPolicy,
// } from "../../apis"; // Assume you have an updatePrivacyPolicy API
// import { bindActionCreators } from "redux";
// import { connect } from "react-redux";
// import actions from "../../actions";
// import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css";
// import Pagination from "../../components/Pagination/Pagination";
// import { Box, Button, CircularProgress, Typography } from "@mui/material";

// const PrivacyPolicies = ({
//   logoutRequest,
//   startLoadingRequest,
//   stopLoadingRequest,
// }) => {
//   const [data, setData] = useState({});
//   const [content, setContent] = useState("");
//   const [isEditing, setIsEditing] = useState(false);
//   const [submitting, setSubmitting] = useState(false);
//   const [loading, setLoading] = useState(true);
//   const [selectedPolicy, setSelectedPolicy] = useState(null);

//   const { pathname, search } = useLocation();

//   const fetch = useCallback(async () => {
//     try {
//       const apiResponse = await getPrivacyPolicies(search);
//       if (apiResponse?.data?.data?.privacyPolicies?.length > 0) {
//         setData({ ...apiResponse?.data?.data });
//         setSelectedPolicy(apiResponse.data.data.privacyPolicies[0]); // Select the first policy by default
//         setContent(apiResponse.data.data.privacyPolicies[0].content); // Assuming "content" is the field containing the policy text
//       } else {
//         setData({});
//       }
//       setLoading(false);
//       stopLoadingRequest();
//     } catch (error) {
//       if (error?.response?.status === 401) {
//         toast.error(error.response.data.message);
//         logoutRequest();
//       } else if (error?.response?.status === 403) {
//         toast.error(error.response.data.message);
//       }
//       setLoading(false);
//       stopLoadingRequest();
//     }
//   }, [search, pathname, logoutRequest, stopLoadingRequest]);

//   useEffect(() => {
//     startLoadingRequest();
//     fetch();
//   }, [pathname, search, startLoadingRequest, fetch]);

//   const handleEdit = () => {
//     if (window.confirm("Do you want to edit the privacy policy?")) {
//       setIsEditing(true);
//     }
//   };

//   const handleSubmit = useCallback(async () => {
//     if (content.trim() === "") {
//       toast.error("Content cannot be empty");
//       return;
//     }

//     setSubmitting(true);

//     try {
//       await updatePrivacyPolicy(selectedPolicy._id, { content }); // Assume updatePrivacyPolicy is your API call to update the policy
//       toast.success("Privacy Policy Updated!");
//       setIsEditing(false);
//       fetch();
//     } catch (error) {
//       console.error("Error updating privacy policy:", error);
//       toast.error("Error Updating Privacy Policy");
//     } finally {
//       setSubmitting(false);
//     }
//   }, [content, selectedPolicy, fetch]);

//   if (loading) {
//     return (
//       <Box
//         display="flex"
//         justifyContent="center"
//         alignItems="center"
//         height="100vh"
//       >
//         <CircularProgress />
//       </Box>
//     );
//   }

//   return (
//     <div className="section">
//       <Typography variant="h4" gutterBottom>
//         Privacy Policies
//       </Typography>
//       <Box sx={{ padding: 4, maxWidth: 800, margin: "auto" }}>
//         <ReactQuill
//           value={content}
//           readOnly={!isEditing}
//           onChange={setContent}
//         />
//         <Box sx={{ display: "flex", gap: 2, mt: 5 }}>
//           <Button
//             variant="contained"
//             color="primary"
//             onClick={handleSubmit}
//             disabled={submitting || !isEditing}
//           >
//             {submitting ? <CircularProgress size={24} /> : "Submit"}
//           </Button>
//           <Button
//             variant="contained"
//             color="secondary"
//             onClick={handleEdit}
//             disabled={isEditing}
//           >
//             Edit
//           </Button>
//         </Box>
//       </Box>
//     </div>
//   );
// };

// const mapDispatchToProps = (dispatch) =>
//   bindActionCreators(
//     {
//       logoutRequest: actions.auth.logout,
//       stopLoadingRequest: actions.loader.stopLoading,
//       startLoadingRequest: actions.loader.startLoading,
//     },
//     dispatch
//   );

// export default connect(null, mapDispatchToProps)(PrivacyPolicies);




import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import {
  getPolicy, // API to get the existing policy content
  getAllPolicy, // API to update the policy content
} from "../../apis";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import actions from "../../actions";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Box, Button, CircularProgress, Typography } from "@mui/material";

const PrivacyPolicies = ({
  logoutRequest,
  startLoadingRequest,
  stopLoadingRequest,
}) => {
  const [content, setContent] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);

  const fetchPolicy = useCallback(async () => {
    try {
      const response = await getPolicy(); // Fetch the existing policy content
      console.log(response);
      if (response.data && response.data.data) {
        setContent(response.data.data.policy || ""); // Pre-fill editor with existing policy or show empty
      } else {
        setContent(""); // If no content is found
      }
      setLoading(false);
      stopLoadingRequest();
    } catch (error) {
      console.error("Error fetching policy", error);
      toast.error("Error fetching policy");
      setLoading(false);
      stopLoadingRequest();
    }
  }, [stopLoadingRequest]);

  useEffect(() => {
    startLoadingRequest();
    fetchPolicy();
  }, [startLoadingRequest, fetchPolicy]);

  const handleEdit = () => {
    if (window.confirm("Do you want to edit the privacy policy?")) {
      setIsEditing(true);
    }
  };

  const handleSubmit = useCallback(async () => {
    if (content.trim() === "") {
      toast.error("Content cannot be empty");
      return;
    }

    setSubmitting(true);

    try {
      const payload = { policy: content }; // Data format expected by the API
      await getAllPolicy(payload); // Send data to the API
      toast.success("Privacy Policy Updated!");
      setIsEditing(false);
      fetchPolicy(); // Fetch the updated content after submission
    } catch (error) {
      console.error("Error updating privacy policy:", error);
      toast.error("Error Updating Privacy Policy");
    } finally {
      setSubmitting(false);
    }
  }, [content, fetchPolicy]);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <div className="section">
        <Typography variant="h4" gutterBottom>
          Privacy Policies
        </Typography>
        <Box sx={{ padding: 4, maxWidth: 800, margin: "auto" }}>
          <ReactQuill
            value={content}
            readOnly={!isEditing}
            onChange={setContent}
          />
          <Box sx={{ display: "flex", gap: 2, mt: 5 }}>
            <Button
              variant="contained"
              // color="primary"
              className="add"
              onClick={handleSubmit}
              disabled={submitting || !isEditing}
            >
              {submitting ? <CircularProgress size={24} /> : "Submit"}
            </Button>
            <Button
              variant="contained"
              // color="secondary"
              className="add"
              onClick={handleEdit}
              disabled={isEditing}
            >
              Edit
            </Button>
          </Box>
        </Box>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logoutRequest: actions.auth.logout,
      stopLoadingRequest: actions.loader.stopLoading,
      startLoadingRequest: actions.loader.startLoading,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(PrivacyPolicies);
