import constants from "../utils/constants";

const activateModal = () => ({
  type: constants.ACITVATE_MODAL,
});

const deactivateModal = () => ({
  type: constants.DEACTIVATE_MODAL,
});

export default {
  activateModal,
  deactivateModal,
};
