import React, { useCallback, useState, useEffect } from "react";
import { IoClose } from "react-icons/io5";
import { toast } from "react-toastify";
import {
  createBlogCategory,
  updateBlogCategory,
  uploadSingleDocument,
} from "../../apis";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import actions from "../../actions";

const AddEdit = ({
  fetch,
  data,
  logoutRequest,
  startLoadingRequest,
  stopLoadingRequest,
  type,
}) => {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    docId: data?.document?._id || "",
    name: data?.name || "",
    image: data?.document?.url || "",
    file: "",
  });

  const [errors, setErrors] = useState({
    docId: "",
    name: "",
  });

  const openHandler = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const changeHandler = useCallback(
    (e) => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    },
    [formData]
  );

  const fileUploadHandler = useCallback(async () => {
    if (typeof formData?.file === "object") {
      const form = new FormData();
      form.append("file", formData.file);
      const apiResponse = await uploadSingleDocument(form);
      return apiResponse?.data?.data?.documentId;
    } else {
      return formData?.docId || "";
    }
  }, [formData]);

  const submitHandler = useCallback(async () => {
    try {
      startLoadingRequest();
      const docId = await fileUploadHandler();
      let cleanData = {
        name: formData?.name?.trim(),
        docId: docId || formData?.docId?.trim(),
      };
      let tempErrors = {};

      if (!cleanData.docId) {
        tempErrors["docId"] = "Image is required";
      }

      if (!cleanData.name) {
        tempErrors["name"] = "name is required";
      }
      if (Object.keys(tempErrors)?.length > 0) {
        setErrors(tempErrors);
        stopLoadingRequest();
        return;
      } else {
        setErrors({});
      }
      if (data?._id) {
        await updateBlogCategory(data?._id, cleanData);
      } else {
        await createBlogCategory(cleanData);
      }
      setOpen(false);
      setFormData({
        docId: data?.document?._id || "",
        name: data?.name || "",
        image: data?.document?.url || "",
        file: "",
      });
      setErrors({
        docId: "",
        name: "",
        image: "",
        file: "",
      });
      fetch();
    } catch (error) {
      stopLoadingRequest();
      toast.error(error?.response?.data?.message);
      if (error?.response?.status === 401) {
        logoutRequest();
      }
    }
  }, [formData, data]);

  useEffect(() => {
    if (data?._id) {
      setFormData({
        docId: data?.document?._id || "",
        name: data?.name || "",
        image: data?.document?.url || "",
        file: "",
      });
    } else {
      setFormData({
        docId: "",
        name: "",
        image: "",
        file: "",
      });
    }
  }, [data?._id]);

  const fileHandler = useCallback(
    (event) => {
      if (event?.target?.files[0]) {
        setFormData({
          ...formData,
          file: event.target.files[0],
          image: URL.createObjectURL(event.target.files[0]),
        });
      }
    },
    [formData]
  );

  return (
    <div className="modal-container">
      <div className="add-modal">
        <button
          className={data?._id ? "secondary" : "add"}
          onClick={openHandler}
        >
          {data?._id ? "Edit" : "Add"}
        </button>
      </div>
      <div className={open ? "modal active" : "modal"}>
        <div className="wrapper">
          <div className="header">
            <h2>{data?._id ? "Edit Blog Category" : "Add Blog Category"}</h2>
            <button type="button" className="close" onClick={openHandler}>
              <IoClose size={18} />
            </button>
          </div>
          <div className="content">
            <div className="form-section">
              <div className="input-wrapper-image">
                <div className="image">
                  {formData.image && <img src={formData.image} />}
                </div>
                <label htmlFor={type}>Upload Image (Aspect ratio - 3:2)</label>
                <input
                  onChange={fileHandler}
                  accept="Image/*"
                  id={type}
                  type="file"
                />
                {errors.docId && <p>{errors.docId}</p>}
              </div>
              <div className="input-wrapper">
                <label>Name</label>
                <input
                  name="name"
                  value={formData.name}
                  onChange={changeHandler}
                />
                {errors.name && <p>{errors.name}</p>}
              </div>
            </div>
          </div>
          <div className="footer">
            <button type="button" onClick={submitHandler} className="primary">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logoutRequest: actions.auth.logout,
      startLoadingRequest: actions.loader.startLoading,
      stopLoadingRequest: actions.loader.stopLoading,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(AddEdit);
