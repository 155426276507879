import auth from "./auth";
import loader from "./loader";
import sidebar from "./sidebar";
import modal from "./modal"

export default {
  auth,
  loader,
  sidebar,
  modal

};
