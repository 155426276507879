import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { getBlogCategories, activateDeactivateBlogCategory } from "../../apis";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import actions from "../../actions";
import AddEdit from "./AddEdit";

const BlogCategories = ({
  logoutRequest,
  startLoadingRequest,
  stopLoadingRequest,
}) => {
  const [data, setData] = useState({});
  const { pathname } = useLocation();
  const fetch = useCallback(async () => {
    try {
      const apiResponse = await getBlogCategories();
      if (apiResponse?.data?.data?.blogCategories?.length > 0) {
        setData({ ...apiResponse?.data?.data });
      } else {
        setData({});
      }
      stopLoadingRequest();
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error(error.response.data.message);
        logoutRequest();
      } else if (error?.response?.status === 403) {
        toast.error(error.response.data.message);
      }
      stopLoadingRequest();
    }
  }, [data, pathname]);

  useEffect(() => {
    startLoadingRequest();
    fetch();
  }, [pathname]);
  const activateDeactivateBlogCategoryHandler = useCallback(
    async (id, requestType) => {
      try {
        startLoadingRequest();
        await activateDeactivateBlogCategory(id, {
          requestType: requestType ? "0" : "1",
        });
        toast.success(
          requestType
            ? "Banner deactivated successfully"
            : "Banner activated successfully"
        );
        fetch();
      } catch (error) {
        if (error?.response?.status === 401) {
          toast.error(error.response.data.message);
          logoutRequest();
        } else if (error?.response?.status === 403) {
          toast.error(error.response.data.message);
        }
        stopLoadingRequest();
      }
    },
    []
  );

  return (
    <div className="section">
      <h1>Blog Categories</h1>
      <div className="wrapper">
        <div className="table-wrapper">
          <div className="table-header">
            <AddEdit type={"blog-category-add"} fetch={fetch} />
          </div>
          <div className="table-container">
            <table>
              <thead>
                <th>#</th>
                <th>Image</th>
                <th>Name</th>
                <th>Active/Inactive</th>
                <th>Actions</th>
              </thead>
              <tbody>
                {data?.blogCategories?.map((item, index) => (
                  <tr key={item._id}>
                    <td>{index + 1}</td>
                    <td>
                      {item?.document?.url && <img src={item.document.url} />}
                    </td>
                    <td>{item.name}</td>
                    <td>{item.isActive ? "Active" : "Inactive"}</td>
                    <td>
                      <div className="table-actions">
                        {item.isActive && (
                          <>
                            <AddEdit
                              type={`blog-category-${item._id}`}
                              data={item}
                              fetch={fetch}
                            />
                          </>
                        )}
                        <button
                          className={item.isActive ? "danger" : "success"}
                          onClick={() =>
                            activateDeactivateBlogCategoryHandler(
                              item._id,
                              item.isActive
                            )
                          }
                        >
                          {item.isActive ? "Deactivate" : "Activate"}
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logoutRequest: actions.auth.logout,
      stopLoadingRequest: actions.loader.stopLoading,
      startLoadingRequest: actions.loader.startLoading,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(BlogCategories);
