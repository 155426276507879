// import React, { useCallback, useEffect, useRef } from "react";
// import { useLocation, useNavigate } from "react-router-dom";
// import routes from "./routes";
// import { bindActionCreators } from "redux";
// import { connect } from "react-redux";
// import actions from "../../actions";
// import "./sidebar.css";

// const Sidebar = ({ admin, deactivateSidebarRequest }) => {
//   const sidebarRef = useRef(null);
//   const navigate = useNavigate();

//   const pathname = useLocation().pathname;

//   const navigationHandler = useCallback((path) => {
//     if (window.innerWidth < 1024) {
//       deactivateSidebarRequest();
//     }
//     navigate(path);
//   }, []);

//   useEffect(() => {
//     document.addEventListener("click", (event) => {
//       if (
//         event.target?.id !== "hamburger" &&
//         event.target?.id !== "hamburger-icon" &&
//         !sidebarRef?.current?.contains(event.target)
//       ) {
//         if (window.innerWidth < 1024) {
//           deactivateSidebarRequest();
//         }
//       }
//     });
//   }, [sidebarRef]);

//   return (
//     <div className="sidebar" ref={sidebarRef}>
//       {routes
//         .filter(
//           (route) =>
//             route.link &&
//             route.isAuthenticated &&
//             route.permission &&
//             admin?.permissions?.indexOf(route.permission) !== -1
//         )
//         .map((route, routeIndex) => (
//           <div
//             onClick={() => navigationHandler(route.link)}
//             key={`route${routeIndex}`}
//             className={
//               route.path.includes(pathname) ? "navlink active" : "navlink"
//             }
//           >
//             {route.icon}
//             <span>{route.label}</span>
//           </div>
//         ))}
//     </div>
//   );
// };

// const mapStateToProps = (state) => ({
//   admin: state.auth.data,
// });

// const mapDispatchToProps = (dispatch) =>
//   bindActionCreators(
//     {
//       deactivateSidebarRequest: actions.sidebar.deactivateSidebar,
//     },
//     dispatch
//   );

// export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);

import React, { useCallback, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import routes from "./routes";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import actions from "../../actions";
import "./sidebar.css";

const Sidebar = ({ admin, deactivateSidebarRequest }) => {
  const sidebarRef = useRef(null);
  const navigate = useNavigate();

  const pathname = useLocation().pathname;

  // Extract permission names from admin permissions array

  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const userPermissions =
    userDetails?.permissions.map((perm) => perm.name) || [];

  const navigationHandler = useCallback(
    (path) => {
      if (window.innerWidth < 1024) {
        deactivateSidebarRequest();
      }
      navigate(path);
    },
    [deactivateSidebarRequest, navigate]
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        event.target?.id !== "hamburger" &&
        event.target?.id !== "hamburger-icon" &&
        !sidebarRef?.current?.contains(event.target)
      ) {
        if (window.innerWidth < 1024) {
          deactivateSidebarRequest();
        }
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [sidebarRef, deactivateSidebarRequest]);

  return (
    <div className="sidebar" ref={sidebarRef}>
      {routes
        .filter(
          (route) =>
            route.link &&
            route.isAuthenticated &&
            (!route.permission || userPermissions.includes(route.permission))
        )
        .map((route, routeIndex) => (
          <div
            onClick={() => navigationHandler(route.link)}
            key={`route${routeIndex}`}
            className={
              route.path.includes(pathname) ? "navlink active" : "navlink"
            }
          >
            {route.icon}
            <span>{route.label}</span>
          </div>
        ))}
    </div>
  );
};

const mapStateToProps = (state) => ({
  admin: state.auth.data,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      deactivateSidebarRequest: actions.sidebar.deactivateSidebar,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
