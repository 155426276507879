import React, { useEffect, useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import actions from "../../actions";
import CreateRole from "../CreateRole";

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      userDetailsRequest: actions.auth.userDetails,
      dataRequest: actions.auth.data,
      loginRequest: actions.auth.login,
      stopLoadingRequest: actions.loader.stopLoading,
      startLoadingRequest: actions.loader.startLoading,
    },
    dispatch
  );

const Role = ({ stopLoadingRequest }) => {
  useEffect(() => {
    const patname = window.location.pathname;
    console.log(patname);
    stopLoadingRequest();
  }, []);

  return <></>;
};

export default connect(null, mapDispatchToProps)(Role);
