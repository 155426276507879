import React from "react";
import "../HelpCenter/HelpCenter.css";
function HumanCoach() {
  return (
    <>
      <div class="chat-wrapper">
        <div className="chat-container section">
          <div className="chat-sidebar">
            <div className="sidebar-header">
              <input type="text" placeholder="search or start new chat" />
            </div>
            <div className="sidebar-chats">
              <div className="chat">
                <div className="chat-info">
                  <h2>Ritesh Gupta</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="main-chat">
            <div className="chat-header">
              <h2>Ritesh</h2>
            </div>
            <div className="chat-window">
              <span className="chat-date">08 August 2024</span>
              <div className="message received">
                Hey! Have you seen Whatsapp Web feature?
              </div>
              <div className="message sent">message send</div>
            </div>
            <div className="chat-input">
              <input type="text" placeholder="Type a message" />
              <button>Send</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HumanCoach;
