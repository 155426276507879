import React, { useEffect } from "react";
import {
  Routes as Switch,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { connect } from "react-redux";
import Sidebar from "../Sidebar/Sidebar";
import routes from "../Sidebar/routes";
import Navbar from "../Navbar/Navbar";
import actions from "../../actions";

const Routes = ({ sidebar, isAuthenticated, admin, isLoading,stopLoadingRequest }) => {
  const navigate = useNavigate();
  useEffect(() => {
    const pathname = window.location.pathname
    if (!localStorage.getItem("authorization") && pathname !== "/resetpasswords") {
      navigate("/login");
    } else {
      if (!isAuthenticated && !isLoading && pathname !== "/resetpasswords") {
        localStorage.removeItem("authorization");
        navigate("/login");
      }
    }
  }, [isLoading, isAuthenticated]);



  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const userPermissions = userDetails?.permissions.map((perm) => perm.name) || [];
  console.log(userPermissions)
  useEffect(() => {
    // Stop the loading spinner after initial load or redirect
    
      stopLoadingRequest();
  
  }, [isLoading, stopLoadingRequest]);
  


  return (
    <Switch>
      <Route
        exact
        path="/*"
        element={
          isAuthenticated ? (
            <Navigate to="/dashboard?requestType=Today" />
          ) : (
            <Navigate to="/login" />
          )
        }
      />
      {routes
        .filter((route) => !route.isAuthenticated)
        .map((route, index) => (
          <Route
            key={`route-${index}`}
            exact
            path={route.path}
            element={route.component}
          />
        ))}

      {routes
        .filter((route) => route.isAuthenticated && !route.permission )
        .map((route, index) => (
          <Route
            key={`route-1${index}`}
            exact
            path={route.path}
            element={
              <div className={sidebar ? "container active" : "container"}>
                <Navbar />
                <Sidebar />
                <div className="body-wrapper">{route.component}</div>
              </div>
            }
          />
        ))}

      {routes
        .filter(
          (route) =>
            route.isAuthenticated &&
            route.permission &&
            userPermissions.includes(route.permission)
        )
        .map((route, index) => (
          <Route
            key={`route-1${index}`}
            exact
            path={route.path}
            element={
              <div className={sidebar ? "container active" : "container"}>
                <Navbar />
                <Sidebar />
                <div className="body-wrapper">{route.component}</div>
              </div>
            }
          />
        ))}
    </Switch>
  );
};

const mapStateToProps = (state) => ({
  sidebar: state.sidebar,
  isAuthenticated: state.auth.isAuthenticated,
  admin: state.auth.data,
  isLoading: state.loader,
  stopLoadingRequest:actions.loader.stopLoading
});

export default connect(mapStateToProps, null)(Routes);
