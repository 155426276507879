import axios from "axios";

export default (endpoint, method, data, isMultipart) =>

  axios({

    url: `https://ponderiee-api.applore.in/api/v1/${endpoint}`,
    method,
    data,
    headers: {
      "Content-Type": isMultipart ? "multipart/form-data" : "application/json",
      Authorization: `Bearer ${localStorage.getItem("authorization")}`,
    },
  });


