// // import React, { useState, useEffect } from "react";
// // import { Link } from "react-router-dom";

// import React, { useState, useEffect, useCallback } from "react";
// import { createCategory, getUserById, updateCategory } from "../../apis"; // Ensure this import matches your actual API import
// import { bindActionCreators } from "redux";
// import { connect } from "react-redux";
// import actions from "../../actions";
// import "./categories.css";
// import { useParams } from "react-router-dom";
// // import Modal from "../../components/Modal/Modal";
// import { toast } from "react-toastify";
// import Pagination from "../../components/Pagination/Pagination";

// const CategoryPage = ({
//   stopLoadingRequest,
//   startLoadingRequest,
//   activateModal,
//   deactivateModal,
// }) => {
//   const [categories, setCategories] = useState([]);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const { id } = useParams();
//   const [selectedCategory, setSelectedCategory] = useState("");
//   const [categoryName, setCategoryName] = useState("");
//   const [stylePreference, setStylePreference] = useState("");

//   const fetchCategories = useCallback(async () => {
//     try {
//       startLoadingRequest();
//       const apiResponse = await getUserById(id);
//       setCategories(apiResponse?.data?.data?.categories);
//     } catch (error) {
//       setCategories([]);
//     } finally {
//       stopLoadingRequest();
//     }
//   }, [startLoadingRequest, stopLoadingRequest]);

//   useEffect(() => {
//     fetchCategories();
//     // setCategories(dummyCategories);
//   }, [fetchCategories]);

//   const handleAddCategory = () => {
//     setSelectedCategory("");
//     setCategoryName("");
//     setStylePreference("");
//     setIsModalOpen(true);
//   };

//   const handleEditCategory = async (category) => {
//     setSelectedCategory(category._id);
//     setCategoryName(category.name);
//     setStylePreference(category.stylePreference);
//     setIsModalOpen(true);
//   };
//   const closeModal = () => {
//     setIsModalOpen(false);
//     //  deactivateModal()
//   };

//   const predefinedStylePreferences = ["Men", "Women"];
//   const createNewCategory = async () => {
//     try {
//       const formData = {
//         userId: id,
//         name: categoryName,
//         stylePreference,
//       };

//       const response = await createCategory(formData);
//       if (response && response.data) {
//         await fetchCategories();
//         toast.success("Category created successfully!");
//         closeModal();
//       } else {
//         throw new Error("Invalid response from server");
//       }
//     } catch (error) {
//       toast.error("Error creating category. Please try again.");
//     } finally {
//       stopLoadingRequest();
//     }
//   };

//   const updateExistingCategory = async () => {
//     try {
//       const formData = {
//         name: categoryName,
//         stylePreference,
//         categoryId: selectedCategory,
//       };

//       const response = await updateCategory(formData);
//       if (response && response.data) {
//         await fetchCategories();
//         toast.success("Category updated successfully!");
//         closeModal();
//       } else {
//         throw new Error("Invalid response from server");
//       }
//     } catch (error) {
//       toast.error("Error updating category. Please try again.");
//     } finally {
//       stopLoadingRequest();
//     }
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     startLoadingRequest();

//     if (selectedCategory) {
//       updateExistingCategory();
//     } else {
//       createNewCategory();
//     }
//   };

//   return (
//     <div style={{ position: "relative" }} >
//       <button
//         className="add-category-button"
//         id="add-category-button-color"
//         onClick={handleAddCategory}
//       >
//         Add Category
//       </button>
//       <div className="table-container">
//         <table className="category-table" id="category-table-color">
//           <thead>
//             <tr>
//               <th>#</th>
//               <th>Category Name</th>
//               {/* <th>Image</th> */}
//               <th>Style Preference</th>
//               <th>Actions</th>
//             </tr>
//           </thead>
//           <tbody>
//             {categories?.map((category, index) =>
//               category ? (
//                 <tr key={category._id}>
//                   <td>{index + 1}</td>
//                   <td>{category?.name}</td>
//                   <td>{category?.stylePreference}</td>
//                   {/* <td>
//                 <img
//                   src={category.image}
//                   alt={category.name}
//                   className="category-image"
//                 />
//               </td> */}
//                   <td>
//                     <div className="table-actions">
//                       <button
//                         onClick={() => handleEditCategory(category)}
//                         className="edit-button"
//                         id="category-button-color"
//                       >
//                         Edit
//                       </button>
//                       <button
//                         className="delete-button"
//                         id="category-button-color"
//                       >
//                         Delete
//                       </button>
//                     </div>
//                   </td>
//                 </tr>
//               ) : null
//             )}
//           </tbody>
//         </table>
//       </div>
//       <Pagination />
//       {isModalOpen && (
//         <div
//           style={{
//             zIndex: "10000",
//             position: "absolute",
//             top: "2rem",
//             left: "10rem",
//             right: "10rem",
//             bottom: "2rem",
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//           }}
//         >
//           <div
//             className="modal-content"
//             style={{
//               backgroundColor: "#fff",
//               borderRadius: "8px",
//               padding: "20px",
//               width: "100%",
//               maxWidth: "600px",
//               boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
//             }}
//           >
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "space-between",
//                 alignItems: "center",
//                 marginBottom: "20px",
//               }}
//             >
//               <h2 style={{ margin: 0 }}>Add New Category</h2>
//               <span
//                 className="close"
//                 onClick={closeModal}
//                 style={{
//                   cursor: "pointer",
//                   fontSize: "24px",
//                   fontWeight: "bold",
//                 }}
//               >
//                 &times;
//               </span>
//             </div>
//             <form onSubmit={handleSubmit}>
//               <div className="form-group" style={{ marginBottom: "20px" }}>
//                 <label
//                   htmlFor="categoryName"
//                   style={{ display: "block", marginBottom: "8px" }}
//                 >
//                   Category Name
//                 </label>
//                 <input
//                   type="text"
//                   id="categoryName"
//                   name="categoryName"
//                   required
//                   value={categoryName}
//                   onChange={(e) => setCategoryName(e.target.value)}
//                   style={{
//                     width: "100%",
//                     padding: "10px",
//                     borderRadius: "4px",
//                     border: "1px solid #ccc",
//                   }}
//                 />
//               </div>
//               {/* <div className="form-group" style={{ marginBottom: "20px" }}>
//                 <label
//                   htmlFor="categoryImage"
//                   style={{ display: "block", marginBottom: "8px" }}
//                 >
//                   Upload Image
//                 </label>
//                 <input
//                   type="file"
//                   id="categoryImage"
//                   name="categoryImage"
//                   accept="image/*"
//                   style={{
//                     width: "100%",
//                     padding: "10px",
//                     borderRadius: "4px",
//                     border: "1px solid #ccc",
//                   }}
//                 />
//               </div> */}

//               <div className="form-group" style={{ marginBottom: "40px" }}>
//                 <label htmlFor="category">Select Category:</label>
//                 <select
//                   id="category"
//                   value={stylePreference}
//                   onChange={(e) => setStylePreference(e.target.value)}
//                   style={{
//                     width: "100%",
//                     padding: "10px",
//                     borderRadius: "4px",
//                     border: "1px solid #ccc",
//                   }}
//                 >
//                   <option value="">ALL</option>
//                   {predefinedStylePreferences.map((preference) => (
//                     <option key={preference} value={preference}>
//                       {preference}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//               <div
//                 className="modal-actions"
//                 style={{
//                   display: "flex",
//                   justifyContent: "flex-end",
//                   gap: "10px",
//                 }}
//               >
//                 <button
//                   type="button"
//                   className="cancel-button"
//                   onClick={closeModal}
//                   style={{
//                     padding: "10px 20px",
//                     borderRadius: "4px",
//                     border: "none",
//                     cursor: "pointer",
//                     backgroundColor: "#090909",
//                     color: "#dfdfdf",
//                   }}
//                 >
//                   Cancel
//                 </button>
//                 <button
//                   type="submit"
//                   className="submit-button"
//                   style={{
//                     padding: "10px 20px",
//                     // backgroundColor: "#007bff",
//                     // color: "#fff",
//                     backgroundColor: "#090909",
//                     color: "#dfdfdf",
//                     borderRadius: "4px",
//                     border: "none",
//                     cursor: "pointer",
//                   }}
//                 >
//                   Save
//                 </button>
//               </div>
//             </form>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// const mapDispatchToProps = (dispatch) =>
//   bindActionCreators(
//     {
//       logoutRequest: actions.auth.logout,
//       stopLoadingRequest: actions.loader.stopLoading,
//       startLoadingRequest: actions.loader.startLoading,
//       activateModal: actions.modal.activateModal,
//     },
//     dispatch
//   );

// export default connect(null, mapDispatchToProps)(CategoryPage);
import React, { useState, useEffect, useCallback, useRef } from "react";
import { createCategory, getUserById, updateCategory } from "../../apis";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import actions from "../../actions";
import "./categories.css";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Pagination from "../../components/Pagination/Pagination";
import { FiMoreVertical } from "react-icons/fi"; // Import the icon for the triple dots

const CategoryPage = ({
  stopLoadingRequest,
  startLoadingRequest,
  activateModal,
  deactivateModal,
}) => {
  const [categories, setCategories] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { id } = useParams();
  const [selectedCategory, setSelectedCategory] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [stylePreference, setStylePreference] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(null); // State to manage the open dropdown
  const dropdownRefs = useRef([]); // Reference for dropdowns

  const fetchCategories = useCallback(async () => {
    try {
      startLoadingRequest();
      const apiResponse = await getUserById(id);
      setCategories(apiResponse?.data?.data?.categories);
    } catch (error) {
      setCategories([]);
    } finally {
      stopLoadingRequest();
    }
  }, [startLoadingRequest, stopLoadingRequest, id]);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  const handleAddCategory = () => {
    setSelectedCategory("");
    setCategoryName("");
    setStylePreference("");
    setIsModalOpen(true);
  };

  const handleEditCategory = async (category) => {
    setSelectedCategory(category._id);
    setCategoryName(category.name);
    setStylePreference(category.stylePreference);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const predefinedStylePreferences = ["Men", "Women"];

  const createNewCategory = async () => {
    try {
      const formData = {
        userId: id,
        name: categoryName,
        stylePreference,
      };

      const response = await createCategory(formData);
      if (response && response.data) {
        await fetchCategories();
        toast.success("Category created successfully!");
        closeModal();
      } else {
        throw new Error("Invalid response from server");
      }
    } catch (error) {
      toast.error("Error creating category. Please try again.");
    } finally {
      stopLoadingRequest();
    }
  };

  const updateExistingCategory = async () => {
    try {
      const formData = {
        name: categoryName,
        stylePreference,
        categoryId: selectedCategory,
      };

      const response = await updateCategory(formData);
      if (response && response.data) {
        await fetchCategories();
        toast.success("Category updated successfully!");
        closeModal();
      } else {
        throw new Error("Invalid response from server");
      }
    } catch (error) {
      toast.error("Error updating category. Please try again.");
    } finally {
      stopLoadingRequest();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    startLoadingRequest();

    if (selectedCategory) {
      updateExistingCategory();
    } else {
      createNewCategory();
    }
  };

  const toggleDropdown = (index) => {
    setDropdownOpen(dropdownOpen === index ? null : index); // Toggle dropdown
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRefs.current &&
        !dropdownRefs.current.some((ref) => ref && ref.contains(event.target))
      ) {
        setDropdownOpen(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    // <div style={{ position: "relative" }}>
    //   <button
    //     className="add-category-button"
    //     id="add-category-button-color"
    //     onClick={handleAddCategory}
    //   >
    //     Add Category
    //   </button>
    //   <div className="table-container">
    //     <table className="category-table" id="category-table-color">
    //       <thead>
    //         <tr>
    //           <th>#</th>
    //           <th>Category Name</th>
    //           <th>Style Preference</th>
    //           <th>Actions</th>
    //         </tr>
    //       </thead>
    //       <tbody>
    //         {categories?.map((category, index) =>
    //           category ? (
    //             <tr key={category._id}>
    //               <td>{index + 1}</td>
    //               <td>{category?.name}</td>
    //               <td>{category?.stylePreference}</td>
    //               <td>
    //                 <div
    //                   className="table-actions"
    //                   ref={(el) => (dropdownRefs.current[index] = el)}
    //                 >
    //                   <div className="dropdown">
    //                     <FiMoreVertical
    //                       size={24}
    //                       onClick={() => toggleDropdown(index)}
    //                       className="dropdown-icon"
    //                     />
    //                     {dropdownOpen === index && (
    //                       <div className="dropdown-menu">
    //                         <button
    //                           onClick={() => {
    //                             toggleDropdown(null);
    //                             handleEditCategory(category);
    //                           }}
    //                           className="dropdown-item"
    //                         >
    //                           Edit
    //                         </button>
    //                         <button
    //                           onClick={() => {
    //                             toggleDropdown(null);
    //                             // Add your delete function here
    //                           }}
    //                           className="dropdown-item"
    //                         >
    //                           Delete
    //                         </button>
    //                       </div>
    //                     )}
    //                   </div>
    //                 </div>
    //               </td>
    //             </tr>
    //           ) : null
    //         )}
    //       </tbody>
    //     </table>
    //   </div>
    //   <div style={{ paddingTop: "20px" }}>
    //     <Pagination />
    //   </div>

    //   {isModalOpen && (
    //     <div
    //       style={{
    //         zIndex: "10000",
    //         position: "absolute",
    //         // top: "0rem",
    //         left: "10rem",
    //         right: "10rem",
    //         bottom: "50rem",
    //         display: "flex",
    //         justifyContent: "center",
    //         alignItems: "center",
    //         // backfaceVisibility: "hidden",
    //         backgroundColor: "rgba(0, 0, 0, 0.4)", // Semi-transparent background
    //         backdropFilter: "blur(10px)", // Blur effect
    //       }}
    //     >
    //       <div
    //         className="modal-content"
    //         style={{
    //           backgroundColor: "#fff",
    //           borderRadius: "8px",
    //           padding: "20px",
    //           width: "100%",
    //           maxWidth: "600px",
    //           boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    //         }}
    //       >
    //         <div
    //           style={{
    //             display: "flex",
    //             justifyContent: "space-between",
    //             alignItems: "center",
    //             marginBottom: "20px",
    //           }}
    //         >
    //           <h2 style={{ margin: 0 }}>Add New Category</h2>
    //           <span
    //             className="close"
    //             onClick={closeModal}
    //             style={{
    //               cursor: "pointer",
    //               fontSize: "24px",
    //               fontWeight: "bold",
    //             }}
    //           >
    //             &times;
    //           </span>
    //         </div>
    //         <form onSubmit={handleSubmit}>
    //           <div className="form-group" style={{ marginBottom: "20px" }}>
    //             <label
    //               htmlFor="categoryName"
    //               style={{ display: "block", marginBottom: "8px" }}
    //             >
    //               Category Name
    //             </label>
    //             <input
    //               type="text"
    //               id="categoryName"
    //               name="categoryName"
    //               required
    //               value={categoryName}
    //               onChange={(e) => setCategoryName(e.target.value)}
    //               style={{
    //                 width: "100%",
    //                 padding: "10px",
    //                 borderRadius: "4px",
    //                 border: "1px solid #ccc",
    //               }}
    //             />
    //           </div>

    //           <div className="form-group" style={{ marginBottom: "40px" }}>
    //             <label htmlFor="category">Select Category:</label>
    //             <select
    //               id="category"
    //               value={stylePreference}
    //               onChange={(e) => setStylePreference(e.target.value)}
    //               style={{
    //                 width: "100%",
    //                 padding: "10px",
    //                 borderRadius: "4px",
    //                 border: "1px solid #ccc",
    //               }}
    //             >
    //               <option value="">ALL</option>
    //               {predefinedStylePreferences.map((preference) => (
    //                 <option key={preference} value={preference}>
    //                   {preference}
    //                 </option>
    //               ))}
    //             </select>
    //           </div>
    //           <div
    //             className="modal-actions"
    //             style={{
    //               display: "flex",
    //               justifyContent: "flex-end",
    //               gap: "10px",
    //             }}
    //           >
    //             <button
    //               type="button"
    //               className="cancel-button"
    //               onClick={closeModal}
    //               style={{
    //                 padding: "10px 20px",
    //                 borderRadius: "4px",
    //                 border: "none",
    //                 cursor: "pointer",
    //                 backgroundColor: "#090909",
    //                 color: "#dfdfdf",
    //               }}
    //             >
    //               Cancel
    //             </button>
    //             <button
    //               type="submit"
    //               className="submit-button"
    //               style={{
    //                 padding: "10px 20px",
    //                 backgroundColor: "#090909",
    //                 color: "#dfdfdf",
    //                 borderRadius: "4px",
    //                 border: "none",
    //                 cursor: "pointer",
    //               }}
    //             >
    //               Save
    //             </button>
    //           </div>
    //         </form>
    //       </div>
    //     </div>
    //   )}
    // </div>

    <div
      style={{ position: "relative", background: "transparent" }}
      className="category-list"
    >
      <button
        className="add-category-button"
        id="add-category-button-color"
        onClick={handleAddCategory}
      >
        Add Category
      </button>
      <div className="table-container">
        <table className="category-table" id="category-table-color">
          <thead>
            <tr>
              <th>#</th>
              <th>Category Name</th>
              <th>Style Preference</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {categories?.map((category, index) =>
              category ? (
                <tr key={category._id}>
                  <td>{index + 1}</td>
                  <td>{category?.name}</td>
                  <td>{category?.stylePreference}</td>
                  <td>
                    <div
                      className="table-actions"
                      ref={(el) => (dropdownRefs.current[index] = el)}
                    >
                      <div className="dropdown">
                        <FiMoreVertical
                          size={24}
                          onClick={() => toggleDropdown(index)}
                          className="dropdown-icon"
                        />
                        {dropdownOpen === index && (
                          <div className="dropdown-menu">
                            <button
                              onClick={() => {
                                toggleDropdown(null);
                                handleEditCategory(category);
                              }}
                              className="dropdown-item"
                            >
                              Edit
                            </button>
                            <button
                              onClick={() => {
                                toggleDropdown(null);
                                // Add your delete function here
                              }}
                              className="dropdown-item"
                            >
                              Delete
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </td>
                </tr>
              ) : null
            )}
          </tbody>
        </table>
      </div>
      <div style={{ paddingTop: "20px" }}>
        <Pagination />
      </div>

      {isModalOpen && (
        <div
          style={{
            zIndex: "10000",
            position: "fixed", // Changed to fixed
            top: 0, // Covering the entire screen
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.4)", // Semi-transparent background
            backdropFilter: "blur(10px)", // Blur effect
          }}
        >
          <div
            className="modal-content"
            style={{
              borderRadius: "8px",
              padding: "30px",
              width: "100%",
              maxWidth: "600px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              fontFamily: "'DM Sans', sans-serif", // Use DM Sans for the modal
              // marginBottom: "50rem",
              backgroundColor: "#e5e5e5",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <h2 style={{ margin: 0 }}>Add New Category</h2>
              <span
                className="close"
                onClick={closeModal}
                style={{
                  cursor: "pointer",
                  fontSize: "24px",
                  fontWeight: "bold",
                }}
              >
                &times;
              </span>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="form-group" style={{ marginBottom: "20px" }}>
                <label
                  htmlFor="categoryName"
                  style={{ display: "block", marginBottom: "8px" }}
                >
                  Category Name
                </label>
                <input
                  type="text"
                  id="categoryName"
                  name="categoryName"
                  required
                  value={categoryName}
                  onChange={(e) => setCategoryName(e.target.value)}
                  style={{
                    width: "100%",
                    padding: "12px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                    fontSize: "14px",
                    fontFamily: "'DM Sans', sans-serif", // Use DM Sans for inputs
                    marginBottom: "10px",
                    boxSizing: "border-box",
                  }}
                />
              </div>

              <div className="form-group" style={{ marginBottom: "40px" }}>
                <label htmlFor="category">Select Category:</label>
                <select
                  id="category"
                  value={stylePreference}
                  onChange={(e) => setStylePreference(e.target.value)}
                  style={{
                    display: "block",
                    marginBottom: "10px",
                    fontWeight: "bold",
                    fontFamily: "'DM Sans', sans-serif", // Use DM Sans for labels
                    fontSize: "16px",
                  }}
                >
                  <option value="">ALL</option>
                  {predefinedStylePreferences.map((preference) => (
                    <option key={preference} value={preference}>
                      {preference}
                    </option>
                  ))}
                </select>
              </div>
              <div
                className="modal-actions"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "10px",
                }}
              >
                <button
                  type="button"
                  className="cancel-button"
                  onClick={closeModal}
                  style={{
                    padding: "10px 20px",
                    borderRadius: "4px",
                    border: "none",
                    cursor: "pointer",
                    backgroundColor: "#090909",
                    color: "#dfdfdf",
                  }}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="submit-button"
                  style={{
                    padding: "10px 20px",
                    backgroundColor: "#090909",
                    color: "#dfdfdf",
                    borderRadius: "4px",
                    border: "none",
                    cursor: "pointer",
                  }}
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
    // );
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logoutRequest: actions.auth.logout,
      stopLoadingRequest: actions.loader.stopLoading,
      startLoadingRequest: actions.loader.startLoading,
      activateModal: actions.modal.activateModal,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(CategoryPage);
