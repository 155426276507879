




// import React, { useState, useEffect } from "react";
// import { bindActionCreators } from "redux";
// import { connect } from "react-redux";
// import actions from "../../actions";
// import "./admincategory.css";

// const AdminCategory = ({
//   stopLoadingRequest,
//   startLoadingRequest,
//   activateModal,
//   deactivateModal,
// }) => {
//   const [categories, setCategories] = useState([]);
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   useEffect(() => {
//     const dummyCategories = [
//       {
//         _id: "1",
//         name: "Bottom Wear",
//         image: "/images/jeans.jpg",
//       },
//       {
//         _id: "2",
//         name: "Formal Wear",
//         image: "/images/jeans.jpg",
//       },
//       {
//         _id: "3",
//         name: "Sportswear",
//         image: "/images/jeans.jpg",
//       },
//     ];
//     setCategories(dummyCategories);
//     stopLoadingRequest();
//   }, []);

//   const handleAddCategory = () => {
//     setIsModalOpen(true);
//   };

//   const closeModal = () => {
//     setIsModalOpen(false);
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     console.log("Form submitted");
//     closeModal();
//   };

//   return (
//     <div className="admin-category-page">
//       <button className="add-category-button" onClick={handleAddCategory}>
//         Add Category
//       </button>
//       <table className="category-table">
//         <thead>
//           <tr>
//             <th>#</th>
//             <th>Category Name</th>
//             <th>Image</th>
//             <th>Actions</th>
//           </tr>
//         </thead>
//         <tbody>
//           {categories.map((category, index) => (
//             <tr key={category._id}>
//               <td>{index + 1}</td>
//               <td>{category?.name}</td>
//               <td>
//                 <img
//                   src={category.image}
//                   alt={category.name}
//                   className="category-image"
//                 />
//               </td>
//               <td>
//                 <div className="table-actions">
//                   <button onClick={handleAddCategory} className="edit-button">
//                     Edit
//                   </button>
//                   <button className="delete-button">Delete</button>
//                 </div>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>

//       {isModalOpen && (
//         <div className="modal">
//           <div className="modal-content">
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "space-between",
//                 alignItems: "center",
//                 marginBottom: "20px",
//               }}
//             >
//               <h2 style={{ margin: 0 }}>Add New Category</h2>
//               <span className="close" onClick={closeModal}>
//                 &times;
//               </span>
//             </div>
//             <form onSubmit={handleSubmit}>
//               <div className="form-group">
//                 <label htmlFor="categoryName">Category Name</label>
//                 <input
//                   type="text"
//                   id="categoryName"
//                   name="categoryName"
//                   required
//                 />
//               </div>
//               <div className="form-group">
//                 <label htmlFor="categoryImage">Upload Image</label>
//                 <input
//                   type="file"
//                   id="categoryImage"
//                   name="categoryImage"
//                   accept="image/*"
//                 />
//               </div>
//               <div className="modal-actions">
//                 <button type="button" className="cancel-button" onClick={closeModal}>
//                   Cancel
//                 </button>
//                 <button type="submit" className="submit-button">
//                   Save
//                 </button>
//               </div>
//             </form>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// const mapDispatchToProps = (dispatch) =>
//   bindActionCreators(
//     {
//       logoutRequest: actions.auth.logout,
//       stopLoadingRequest: actions.loader.stopLoading,
//       startLoadingRequest: actions.loader.startLoading,
//       activateModal: actions.modal.activateModal,
//     },
//     dispatch
//   );

// export default connect(null, mapDispatchToProps)(AdminCategory);





import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import actions from "../../actions";
import "./admincategory.css";

const AdminCategory = ({
  stopLoadingRequest,
  startLoadingRequest,
  activateModal,
  deactivateModal,
}) => {
  const [categories, setCategories] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const dummyCategories = [
      {
        _id: "1",
        name: "Bottom Wear",
        image: "/images/jeans.jpg",
      },
      {
        _id: "2",
        name: "Formal Wear",
        image: "/images/jeans.jpg",
      },
      {
        _id: "3",
        name: "Sportswear",
        image: "/images/jeans.jpg",
      },
    ];
    setCategories(dummyCategories);
    stopLoadingRequest();
  }, []);

  const handleAddCategory = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted");
    closeModal();
  };

  return (
    <div className="admin-category-page">
      <button className="add-category-button" onClick={handleAddCategory}>
        Add Category
      </button>
      <table className="category-table">
        <thead>
          <tr>
            <th>#</th>
            <th>Category Name</th>
            <th>Image</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {categories.map((category, index) => (
            <tr key={category._id}>
              <td>{index + 1}</td>
              <td>{category?.name}</td>
              <td>
                <img
                  src={category.image}
                  alt={category.name}
                  className="category-image"
                />
              </td>
              <td>
                <div className="table-actions">
                  <button onClick={handleAddCategory} className="edit-button">
                    Edit
                  </button>
                  <button className="delete-button">Delete</button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <h2 style={{ margin: 0 }}>Add New Category</h2>
              <span className="close" onClick={closeModal}>
                &times;
              </span>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="categoryName">Category Name</label>
                <input
                  type="text"
                  id="categoryName"
                  name="categoryName"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="categoryImage">Upload Image</label>
                <input
                  type="file"
                  id="categoryImage"
                  name="categoryImage"
                  accept="image/*"
                />
              </div>
              <div className="modal-actions">
                <button type="button" className="cancel-button" onClick={closeModal}>
                  Cancel
                </button>
                <button type="submit" className="submit-button">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logoutRequest: actions.auth.logout,
      stopLoadingRequest: actions.loader.stopLoading,
      startLoadingRequest: actions.loader.startLoading,
      activateModal: actions.modal.activateModal,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(AdminCategory);


