// import React, { useState, useEffect, useCallback } from "react";
// import { Link, useParams } from "react-router-dom";
// // import { getCategories, getSubcategories } from "../../apis"; // Ensure these API calls exist
// import { bindActionCreators } from "redux";
// import { connect } from "react-redux";
// import actions from "../../actions";
// import "./SubcategoryPage.css";
// import { createSubCategory, getUserById, updateSubCategory } from "../../apis";
// import { toast } from "react-toastify";

// const SubcategoryPage = ({ startLoadingRequest, stopLoadingRequest }) => {
//   const [categories, setCategories] = useState([]);
//   const [subcategories, setSubcategories] = useState([]);
//   const [selectedCategory, setSelectedCategory] = useState("");
//   const [loading, setLoading] = useState(true);
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const [subCategoryName, setSubCategoryName] = useState("");
//   const [stylePreference, setStylePreference] = useState("");
//   const [editingSubCategoryId, setEditingSubCategoryId] = useState(null);
//   const [categoryId, setCategoryId] = useState(null);

//   const { id } = useParams();
//   // console.log(id);

//   const fetchCategoriesData = useCallback(async () => {
//     try {
//       startLoadingRequest();
//       const response = await getUserById(id);
//       // console.log("response",response);

//       const { categories, subCategory } = response.data.data;
//       setCategories(categories || []);
//       setSubcategories(subCategory || []);
//       stopLoadingRequest();
//       setLoading(false);
//     } catch (error) {
//       console.error("Error fetching categories:", error);
//       setSubcategories([]);
//       setCategories([]);
//       setSelectedCategory("");
//       stopLoadingRequest();
//       setLoading(false);
//     }
//   }, [startLoadingRequest, stopLoadingRequest]);

//   useEffect(() => {
//     fetchCategoriesData();
//   }, [fetchCategoriesData]);

//   const handleAddSubCategory = () => {
//     setSubCategoryName("");
//     setStylePreference("");
//     setIsModalOpen(true);
//   };

//   const handleEdit = (subcategory) => {
//     setEditingSubCategoryId(subcategory._id);
//     setSubCategoryName(subcategory.name);
//     setStylePreference(subcategory.categoryId?._id);
//     setIsModalOpen(true);
//   };

//   const closeModal = () => {
//     setIsModalOpen(false);
//   };

//   const handleCreateSubCategory = async () => {
//     try {
//       startLoadingRequest();
//       const subCategoryFormData = {
//         userId: id,
//         name: subCategoryName,
//         stylePreference: "women",
//       };
//       const response = await createSubCategory(subCategoryFormData, categoryId);
//       if (response && response.data) {
//         await fetchCategoriesData();
//         toast.success("Subcategory created successfully!");
//       } else {
//         throw new Error("Invalid response from server");
//       }
//     } catch (error) {
//       toast.error("Failed to create subcategory.");
//     } finally {
//       stopLoadingRequest();
//       closeModal();
//     }
//   };

//   const handleUpdateSubCategory = async () => {
//     try {
//       startLoadingRequest();
//       const subCategoryFormData = {
//         userId: id,
//         name: subCategoryName,
//         subcategoryId: editingSubCategoryId,
//         stylePreference: "women",
//       };
//       const response = await updateSubCategory(subCategoryFormData);
//       if (response && response.data) {
//         await fetchCategoriesData();
//         toast.success("Subcategory updated successfully!");
//       } else {
//         throw new Error("Invalid response from server");
//       }
//     } catch (error) {
//       toast.error("Failed to update subcategory.");
//     } finally {
//       stopLoadingRequest();
//       closeModal();
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (editingSubCategoryId) {
//       await handleUpdateSubCategory();
//     } else {
//       await handleCreateSubCategory();
//     }
//   };

//   // const filteredSubcategories = selectedCategory
//   //   ? subcategories.filter(
//   //       (subcategory) => subcategory.category === selectedCategory
//   //     )
//   //   : subcategories;

//   if (loading) {
//     return <div>Loading...</div>;
//   }
//   return (
//     <div className="subcategory-page">
//       <h1>Subcategories</h1>
//       {categories.length === 0 && subcategories.length === 0 ? (
//         <p className="no-items-message">Items not found</p>
//       ) : (
//         <>
//           <div className="category-select">
//             <div>
//               <label htmlFor="category">Select Category:</label>
//               <select
//                 id="category"
//                 value={selectedCategory}
//                 onChange={(e) => setSelectedCategory(e.target.value)}
//                 style={{
//                   border: "1px solid #000",
//                   backgroundColor: "#E7E5E3",
//                 }}
//               >
//                 <option value="">ALL</option>

//                 {categories?.map((category) => (
//                   <option key={category._id} value={category._id}>
//                     {category.name}
//                   </option>
//                 ))}
//               </select>
//             </div>
//             <button
//               className="add-subcategory-button"
//               id="add-subcategory-button-color"
//               onClick={handleAddSubCategory}
//             >
//               Add Subcategory
//             </button>
//           </div>
//           <div className="subcategory-table-container">
//             <table className="subcategory-table">
//               <thead>
//                 <tr>
//                   <th>#</th>
//                   <th>Subcategory Name</th>
//                   {/* <th>Image</th> */}
//                   <th>Category</th>
//                   <th>Actions</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {subcategories?.map((subcategory, index) => (
//                   <tr key={subcategory._id}>
//                     <td>{index + 1}</td>
//                     <td>{subcategory.name}</td>
//                     {/* <td>
//                 <img
//                   src={subcategory.image}
//                   alt={subcategory.name}
//                   className="subcategory-image"
//                 />
//               </td> */}
//                     <td>{subcategory.categoryId?.name}</td>
//                     <td>
//                       <div className="table-actions">
//                         {/* <Link
//                         to={`/subcategory/edit/${subcategory._id}`}
//                         className="edit-button"
//                       >
//                         Edit
//                       </Link> */}
//                         <button
//                           className="edit-button"
//                           id="subcategory-button-color"
//                           onClick={handleEdit}
//                         >
//                           Edit
//                         </button>

//                         <button
//                           className="delete-button"
//                           id="subcategory-button-color"
//                         >
//                           Delete
//                         </button>
//                       </div>
//                     </td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           </div>
//         </>
//       )}

//       {isModalOpen && (
//         <div
//           style={{
//             zIndex: "10000",
//             position: "absolute",
//             top: "2rem",
//             left: "10rem",
//             right: "10rem",
//             bottom: "2rem",
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//           }}
//         >
//           <div
//             className="modal-content"
//             style={{
//               backgroundColor: "#fff",
//               borderRadius: "8px",
//               padding: "20px",
//               width: "100%",
//               maxWidth: "600px",
//               boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
//             }}
//           >
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "space-between",
//                 alignItems: "center",
//                 marginBottom: "20px",
//               }}
//             >
//               <h2 style={{ margin: 0 }}>Add New Subcategory</h2>
//               <span
//                 className="close"
//                 onClick={closeModal}
//                 style={{
//                   cursor: "pointer",
//                   fontSize: "24px",
//                   fontWeight: "bold",
//                 }}
//               >
//                 &times;
//               </span>
//             </div>
//             <form onSubmit={handleSubmit}>
//               <div className="form-group" style={{ marginBottom: "20px" }}>
//                 <label
//                   htmlFor="categoryName"
//                   style={{ display: "block", marginBottom: "8px" }}
//                 >
//                   Subcategory Name
//                 </label>
//                 <input
//                   type="text"
//                   id="subCategoryName"
//                   name="subCategoryName"
//                   value={subCategoryName}
//                   onChange={(e) => setSubCategoryName(e.target.value)}
//                   required
//                   style={{
//                     width: "100%",
//                     padding: "10px",
//                     borderRadius: "4px",
//                     border: "1px solid #ccc",
//                   }}
//                 />
//               </div>
//               <div className="form-group" style={{ marginBottom: "20px" }}>
//                 <label htmlFor="category">Select Category:</label>
//                 <select
//                   id="category"
//                   value={stylePreference}
//                   onChange={(e) => {
//                     // console.log(e.target.value)
//                     setCategoryId(e.target.value);
//                   }}
//                   style={{
//                     width: "100%",
//                     padding: "10px",
//                     borderRadius: "4px",
//                     border: "1px solid #ccc",
//                   }}
//                 >
//                   <option value="">ALL</option>
//                   {categories?.map((category) => (
//                     <option key={category._id} value={category._id}>
//                       {category.name}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//               {/* <div className="form-group" style={{ marginBottom: "20px" }}>
//                 <label
//                   htmlFor="categoryImage"
//                   style={{ display: "block", marginBottom: "8px" }}
//                 >
//                   Upload Image
//                 </label>
//                 <input
//                   type="file"
//                   id="categoryImage"
//                   name="categoryImage"
//                   accept="image/*"
//                   style={{
//                     width: "100%",
//                     padding: "10px",
//                     borderRadius: "4px",
//                     border: "1px solid #ccc",
//                   }}
//                 />
//               </div> */}
//               <div
//                 className="modal-actions"
//                 style={{
//                   display: "flex",
//                   justifyContent: "flex-end",
//                   gap: "10px",
//                 }}
//               >
//                 <button
//                   type="button"
//                   className="cancel-button"
//                   onClick={closeModal}
//                   style={{
//                     padding: "10px 20px",
//                     borderRadius: "4px",
//                     border: "none",
//                     cursor: "pointer",
//                     backgroundColor: "#090909",
//                     color: "#dfdfdf",
//                   }}
//                 >
//                   Cancel
//                 </button>
//                 <button
//                   type="submit"
//                   className="submit-button"
//                   style={{
//                     padding: "10px 20px",
//                     // backgroundColor: "#007bff",
//                     // color: "#fff",
//                     backgroundColor: "#090909",
//                     color: "#dfdfdf",
//                     borderRadius: "4px",
//                     border: "none",
//                     cursor: "pointer",
//                   }}
//                 >
//                   Save
//                 </button>
//               </div>
//             </form>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// const mapDispatchToProps = (dispatch) =>
//   bindActionCreators(
//     {
//       logoutRequest: actions.auth.logout,
//       stopLoadingRequest: actions.loader.stopLoading,
//       startLoadingRequest: actions.loader.startLoading,
//     },
//     dispatch
//   );

// export default connect(null, mapDispatchToProps)(SubcategoryPage);

// import React, { useState, useEffect, useCallback, useRef } from "react";
// import { Link, useParams } from "react-router-dom";
// import { bindActionCreators } from "redux";
// import { connect } from "react-redux";
// import actions from "../../actions";
// import { createSubCategory, getUserById, updateSubCategory } from "../../apis";
// import { toast } from "react-toastify";
// import { FiMoreVertical } from "react-icons/fi"; // Import the triple dots icon
// import "./SubcategoryPage.css";

// const SubcategoryPage = ({ startLoadingRequest, stopLoadingRequest }) => {
//   const [categories, setCategories] = useState([]);
//   const [subcategories, setSubcategories] = useState([]);
//   const [selectedCategory, setSelectedCategory] = useState("");
//   const [loading, setLoading] = useState(true);
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const [subCategoryName, setSubCategoryName] = useState("");
//   const [stylePreference, setStylePreference] = useState("");
//   const [editingSubCategoryId, setEditingSubCategoryId] = useState(null);
//   const [categoryId, setCategoryId] = useState(null);
//   const [dropdownOpen, setDropdownOpen] = useState(null); // State to manage open dropdown
//   const dropdownRefs = useRef([]); // Reference to manage dropdowns

//   const { id } = useParams();

//   const fetchCategoriesData = useCallback(async () => {
//     try {
//       startLoadingRequest();
//       const response = await getUserById(id);
//       const { categories, subCategory } = response.data.data;
//       setCategories(categories || []);
//       setSubcategories(subCategory || []);
//       stopLoadingRequest();
//       setLoading(false);
//     } catch (error) {
//       console.error("Error fetching categories:", error);
//       setSubcategories([]);
//       setCategories([]);
//       setSelectedCategory("");
//       stopLoadingRequest();
//       setLoading(false);
//     }
//   }, [startLoadingRequest, stopLoadingRequest, id]);

//   useEffect(() => {
//     fetchCategoriesData();
//   }, [fetchCategoriesData]);

//   const handleAddSubCategory = () => {
//     setSubCategoryName("");
//     setStylePreference("");
//     setIsModalOpen(true);
//   };

//   const handleEdit = (subcategory) => {
//     setEditingSubCategoryId(subcategory._id);
//     setSubCategoryName(subcategory.name);
//     setStylePreference(subcategory.categoryId?._id);
//     setIsModalOpen(true);
//   };

//   const closeModal = () => {
//     setIsModalOpen(false);
//   };

//   const handleCreateSubCategory = async () => {
//     try {
//       startLoadingRequest();
//       const subCategoryFormData = {
//         userId: id,
//         name: subCategoryName,
//         stylePreference: "women",
//       };
//       const response = await createSubCategory(subCategoryFormData, categoryId);
//       if (response && response.data) {
//         await fetchCategoriesData();
//         toast.success("Subcategory created successfully!");
//       } else {
//         throw new Error("Invalid response from server");
//       }
//     } catch (error) {
//       toast.error("Failed to create subcategory.");
//     } finally {
//       stopLoadingRequest();
//       closeModal();
//     }
//   };

//   const handleUpdateSubCategory = async () => {
//     try {
//       startLoadingRequest();
//       const subCategoryFormData = {
//         userId: id,
//         name: subCategoryName,
//         subcategoryId: editingSubCategoryId,
//         stylePreference: "women",
//       };
//       const response = await updateSubCategory(subCategoryFormData);
//       if (response && response.data) {
//         await fetchCategoriesData();
//         toast.success("Subcategory updated successfully!");
//       } else {
//         throw new Error("Invalid response from server");
//       }
//     } catch (error) {
//       toast.error("Failed to update subcategory.");
//     } finally {
//       stopLoadingRequest();
//       closeModal();
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (editingSubCategoryId) {
//       await handleUpdateSubCategory();
//     } else {
//       await handleCreateSubCategory();
//     }
//   };

//   const toggleDropdown = (index) => {
//     setDropdownOpen(dropdownOpen === index ? null : index); // Toggle dropdown
//   };

//   // Close dropdown when clicking outside
//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (
//         dropdownRefs.current &&
//         !dropdownRefs.current.some((ref) => ref && ref.contains(event.target))
//       ) {
//         setDropdownOpen(null);
//       }
//     };
//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, []);

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <div className="subcategory-page">
//       <h1>Subcategories</h1>
//       {categories.length === 0 && subcategories.length === 0 ? (
//         <p className="no-items-message">Items not found</p>
//       ) : (
//         <>
//           <div className="category-select">
//             <div>
//               <label htmlFor="category">Select Category:</label>
//               <select
//                 id="category"
//                 value={selectedCategory}
//                 onChange={(e) => setSelectedCategory(e.target.value)}
//                 style={{
//                   border: "1px solid #000",
//                   backgroundColor: "#E7E5E3",
//                 }}
//               >
//                 <option value="">ALL</option>

//                 {categories?.map((category) => (
//                   <option key={category._id} value={category._id}>
//                     {category.name}
//                   </option>
//                 ))}
//               </select>
//             </div>
//             <button
//               className="add-subcategory-button"
//               id="add-subcategory-button-color"
//               onClick={handleAddSubCategory}
//             >
//               Add Subcategory
//             </button>
//           </div>
//           <div className="subcategory-table-container">
//             <table className="subcategory-table">
//               <thead>
//                 <tr>
//                   <th>#</th>
//                   <th>Subcategory Name</th>
//                   <th>Category</th>
//                   <th>Actions</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {subcategories?.map((subcategory, index) => (
//                   <tr key={subcategory._id}>
//                     <td>{index + 1}</td>
//                     <td>{subcategory.name}</td>
//                     <td>{subcategory.categoryId?.name}</td>
//                     <td>
//                       <div
//                         className="table-actions"
//                         style={{ position: "relative" }}
//                         ref={(el) => (dropdownRefs.current[index] = el)}
//                       >
//                         <div className="dropdown">
//                           <FiMoreVertical
//                             size={24}
//                             onClick={() => toggleDropdown(index)}
//                             className="dropdown-icon"
//                           />
//                           {dropdownOpen === index && (
//                             <div className="dropdown-menu" style={{ right: 0 }}>
//                               <button
//                                 onClick={() => {
//                                   toggleDropdown(null);
//                                   handleEdit(subcategory);
//                                 }}
//                                 className="dropdown-item"
//                               >
//                                 Edit
//                               </button>
//                               <button
//                                 onClick={() => {
//                                   toggleDropdown(null);
//                                   // Add your delete function here
//                                 }}
//                                 className="dropdown-item"
//                               >
//                                 Delete
//                               </button>
//                             </div>
//                           )}
//                         </div>
//                       </div>
//                     </td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           </div>
//         </>
//       )}

//       {isModalOpen && (
//         <div
//           style={{
//             zIndex: "10000",
//             position: "fixed",
//             top: 0,
//             left: 0,
//             right: 0,
//             bottom: 0,
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             backgroundColor: "rgba(0, 0, 0, 0.4)",
//             backdropFilter: "blur(10px)",
//           }}
//         >
//           <div
//             className="modal-content"
//             style={{
//               backgroundColor: "#fff",
//               borderRadius: "8px",
//               padding: "20px",
//               width: "100%",
//               maxWidth: "600px",
//               boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
//             }}
//           >
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "space-between",
//                 alignItems: "center",
//                 marginBottom: "20px",
//               }}
//             >
//               <h2 style={{ margin: 0 }}>Add New Subcategory</h2>
//               <span
//                 className="close"
//                 onClick={closeModal}
//                 style={{
//                   cursor: "pointer",
//                   fontSize: "24px",
//                   fontWeight: "bold",
//                 }}
//               >
//                 &times;
//               </span>
//             </div>
//             <form onSubmit={handleSubmit}>
//               <div className="form-group" style={{ marginBottom: "20px" }}>
//                 <label
//                   htmlFor="subCategoryName"
//                   style={{ display: "block", marginBottom: "8px" }}
//                 >
//                   Subcategory Name
//                 </label>
//                 <input
//                   type="text"
//                   id="subCategoryName"
//                   name="subCategoryName"
//                   value={subCategoryName}
//                   onChange={(e) => setSubCategoryName(e.target.value)}
//                   required
//                   style={{
//                     width: "100%",
//                     padding: "10px",
//                     borderRadius: "4px",
//                     border: "1px solid #ccc",
//                   }}
//                 />
//               </div>
//               <div className="form-group" style={{ marginBottom: "20px" }}>
//                 <label htmlFor="category">Select Category:</label>
//                 <select
//                   id="category"
//                   value={stylePreference}
//                   onChange={(e) => {
//                     setCategoryId(e.target.value);
//                   }}
//                   style={{
//                     width: "100%",
//                     padding: "10px",
//                     borderRadius: "4px",
//                     border: "1px solid #ccc",
//                   }}
//                 >
//                   <option value="">ALL</option>
//                   {categories?.map((category) => (
//                     <option key={category._id} value={category._id}>
//                       {category.name}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//               <div
//                 className="modal-actions"
//                 style={{
//                   display: "flex",
//                   justifyContent: "flex-end",
//                   gap: "10px",
//                 }}
//               >
//                 <button
//                   type="button"
//                   className="cancel-button"
//                   onClick={closeModal}
//                   style={{
//                     padding: "10px 20px",
//                     borderRadius: "4px",
//                     border: "none",
//                     cursor: "pointer",
//                     backgroundColor: "#090909",
//                     color: "#dfdfdf",
//                   }}
//                 >
//                   Cancel
//                 </button>
//                 <button
//                   type="submit"
//                   className="submit-button"
//                   style={{
//                     padding: "10px 20px",
//                     backgroundColor: "#090909",
//                     color: "#dfdfdf",
//                     borderRadius: "4px",
//                     border: "none",
//                     cursor: "pointer",
//                   }}
//                 >
//                   Save
//                 </button>
//               </div>
//             </form>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// const mapDispatchToProps = (dispatch) =>
//   bindActionCreators(
//     {
//       logoutRequest: actions.auth.logout,
//       stopLoadingRequest: actions.loader.stopLoading,
//       startLoadingRequest: actions.loader.startLoading,
//     },
//     dispatch
//   );

// export default connect(null, mapDispatchToProps)(SubcategoryPage);

import React, { useState, useEffect, useCallback, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import actions from "../../actions";
import { createSubCategory, getUserById, updateSubCategory } from "../../apis";
import { toast } from "react-toastify";
import { FiMoreVertical } from "react-icons/fi"; // Import the triple dots icon
import "./SubcategoryPage.css";

const SubcategoryPage = ({ startLoadingRequest, stopLoadingRequest }) => {
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [subCategoryName, setSubCategoryName] = useState("");
  const [stylePreference, setStylePreference] = useState("");
  const [editingSubCategoryId, setEditingSubCategoryId] = useState(null);
  const [categoryId, setCategoryId] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(null); // State to manage open dropdown
  const dropdownRefs = useRef([]); // Reference to manage dropdowns
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // You can adjust the number of items per page

  const { id } = useParams();

  const fetchCategoriesData = useCallback(async () => {
    try {
      startLoadingRequest();
      const response = await getUserById(id);
      const { categories, subCategory } = response.data.data;
      setCategories(categories || []);
      setSubcategories(subCategory || []);
      stopLoadingRequest();
      setLoading(false);
    } catch (error) {
      console.error("Error fetching categories:", error);
      setSubcategories([]);
      setCategories([]);
      setSelectedCategory("");
      stopLoadingRequest();
      setLoading(false);
    }
  }, [startLoadingRequest, stopLoadingRequest, id]);

  useEffect(() => {
    fetchCategoriesData();
  }, [fetchCategoriesData]);

  const handleAddSubCategory = () => {
    setSubCategoryName("");
    setStylePreference("");
    setIsModalOpen(true);
  };

  const handleEdit = (subcategory) => {
    setEditingSubCategoryId(subcategory._id);
    setSubCategoryName(subcategory.name);
    setStylePreference(subcategory.categoryId?._id);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleCreateSubCategory = async () => {
    try {
      startLoadingRequest();
      const subCategoryFormData = {
        userId: id,
        name: subCategoryName,
        stylePreference: "women",
      };
      const response = await createSubCategory(subCategoryFormData, categoryId);
      if (response && response.data) {
        await fetchCategoriesData();
        toast.success("Subcategory created successfully!");
      } else {
        throw new Error("Invalid response from server");
      }
    } catch (error) {
      toast.error("Failed to create subcategory.");
    } finally {
      stopLoadingRequest();
      closeModal();
    }
  };

  const handleUpdateSubCategory = async () => {
    try {
      startLoadingRequest();
      const subCategoryFormData = {
        userId: id,
        name: subCategoryName,
        subcategoryId: editingSubCategoryId,
        stylePreference: "women",
      };
      const response = await updateSubCategory(subCategoryFormData);
      if (response && response.data) {
        await fetchCategoriesData();
        toast.success("Subcategory updated successfully!");
      } else {
        throw new Error("Invalid response from server");
      }
    } catch (error) {
      toast.error("Failed to update subcategory.");
    } finally {
      stopLoadingRequest();
      closeModal();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (editingSubCategoryId) {
      await handleUpdateSubCategory();
    } else {
      await handleCreateSubCategory();
    }
  };

  const toggleDropdown = (index) => {
    setDropdownOpen(dropdownOpen === index ? null : index); // Toggle dropdown
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRefs.current &&
        !dropdownRefs.current.some((ref) => ref && ref.contains(event.target))
      ) {
        setDropdownOpen(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentSubcategories = subcategories.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const totalPages = Math.ceil(subcategories.length / itemsPerPage);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="subcategory-page">
      <h1>Subcategories</h1>
      {categories.length === 0 && subcategories.length === 0 ? (
        <p className="no-items-message">Items not found</p>
      ) : (
        <>
          <div className="category-select">
            <div>
              <label htmlFor="category">Select Category:</label>
              <select
                id="category"
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value)}
                style={{
                  border: "1px solid #000",
                  backgroundColor: "#E7E5E3",
                }}
              >
                <option value="">ALL</option>

                {categories?.map((category) => (
                  <option key={category._id} value={category._id}>
                    {category.name}
                  </option>
                ))}
              </select>
            </div>
            <button
              className="add-subcategory-button"
              id="add-subcategory-button-color"
              onClick={handleAddSubCategory}
            >
              Add Subcategory
            </button>
          </div>
          <div className="subcategory-table-container">
            <table className="subcategory-table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Subcategory Name</th>
                  <th>Category</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentSubcategories?.map((subcategory, index) => (
                  <tr key={subcategory._id}>
                    <td>{indexOfFirstItem + index + 1}</td>
                    <td>{subcategory.name}</td>
                    <td>{subcategory.categoryId?.name}</td>
                    <td>
                      <div
                        className="table-actions"
                        style={{ position: "relative" }}
                        ref={(el) => (dropdownRefs.current[index] = el)}
                      >
                        <div className="dropdown">
                          <FiMoreVertical
                            size={24}
                            onClick={() => toggleDropdown(index)}
                            className="dropdown-icon"
                          />
                          {dropdownOpen === index && (
                            <div className="dropdown-menu" style={{ right: 0 }}>
                              <button
                                onClick={() => {
                                  toggleDropdown(null);
                                  handleEdit(subcategory);
                                }}
                                className="dropdown-item"
                              >
                                Edit
                              </button>
                              <button
                                onClick={() => {
                                  toggleDropdown(null);
                                  // Add your delete function here
                                }}
                                className="dropdown-item"
                              >
                                Delete
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="pagination">
            <button
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </>
      )}

      {isModalOpen && (
        <div
          style={{
            zIndex: "10000",
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.4)",
            backdropFilter: "blur(10px)",
          }}
        >
          <div
            className="modal-content"
            style={{
              backgroundColor: "#fff",
              borderRadius: "8px",
              padding: "20px",
              width: "100%",
              maxWidth: "600px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <h2 style={{ margin: 0 }}>Add New Subcategory</h2>
              <span
                className="close"
                onClick={closeModal}
                style={{
                  cursor: "pointer",
                  fontSize: "24px",
                  fontWeight: "bold",
                }}
              >
                &times;
              </span>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="form-group" style={{ marginBottom: "20px" }}>
                <label
                  htmlFor="subCategoryName"
                  style={{ display: "block", marginBottom: "8px" }}
                >
                  Subcategory Name
                </label>
                <input
                  type="text"
                  id="subCategoryName"
                  name="subCategoryName"
                  value={subCategoryName}
                  onChange={(e) => setSubCategoryName(e.target.value)}
                  required
                  style={{
                    width: "100%",
                    padding: "10px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                  }}
                />
              </div>
              <div className="form-group" style={{ marginBottom: "20px" }}>
                <label htmlFor="category">Select Category:</label>
                <select
                  id="category"
                  value={stylePreference}
                  onChange={(e) => {
                    setCategoryId(e.target.value);
                  }}
                  style={{
                    width: "100%",
                    padding: "10px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                  }}
                >
                  <option value="">ALL</option>
                  {categories?.map((category) => (
                    <option key={category._id} value={category._id}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>
              <div
                className="modal-actions"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "10px",
                }}
              >
                <button
                  type="button"
                  className="cancel-button"
                  onClick={closeModal}
                  style={{
                    padding: "10px 20px",
                    borderRadius: "4px",
                    border: "none",
                    cursor: "pointer",
                    backgroundColor: "#090909",
                    color: "#dfdfdf",
                  }}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="submit-button"
                  style={{
                    padding: "10px 20px",
                    backgroundColor: "#090909",
                    color: "#dfdfdf",
                    borderRadius: "4px",
                    border: "none",
                    cursor: "pointer",
                  }}
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logoutRequest: actions.auth.logout,
      stopLoadingRequest: actions.loader.stopLoading,
      startLoadingRequest: actions.loader.startLoading,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(SubcategoryPage);
