// import React, { useState, useEffect } from "react";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import { useParams } from "react-router-dom";
// import actions from "../../actions";
// import { getUserById } from "../../apis";
// import {
//   Box,
//   Card,
//   CardContent,
//   Typography,
//   Container,
//   Grid,
// } from "@mui/material";
// import "./planner.css"; // Custom styling

// const Planner = ({
//   logoutRequest,
//   startLoadingRequest,
//   stopLoadingRequest,
// }) => {
//   const { id } = useParams();
//   const [plannerData, setPlannerData] = useState([]);
//   const [wardrobePs, setwardrobePs] = useState([]);
//   const [outfits, setOutfits] = useState([]);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         startLoadingRequest();
//         const response = await getUserById(id); // Fetching user data by ID
//         const userData = response?.data?.data;

//         // Extracting planner, wardrobePs, and outfits from the response
//         setPlannerData(userData?.planners || []);
//         setwardrobePs(userData?.waredrob || []);
//         setOutfits(userData?.outfits || []);

//         stopLoadingRequest();
//       } catch (error) {
//         stopLoadingRequest();
//         console.error("Error fetching planner data:", error);
//         if (error?.response?.status === 401) {
//           logoutRequest();
//         }
//       }
//     };

//     fetchData();
//   }, [id, startLoadingRequest, stopLoadingRequest, logoutRequest]);

//   const findItemById = (array, id) => {
//     return array.find((item) => item._id === id) || {};
//   };

//   return (
//     <Container maxWidth="lg">
//       <Box sx={{ padding: "20px 0" }}>
//         {plannerData.length > 0 ? (
//           plannerData.map((plan, index) => {
//             const outfit = findItemById(outfits, plan.outfitId);
//             const wardrobeP = findItemById(wardrobePs, plan.wardrobePId);

//             return (
//               <Box key={index} sx={{ marginBottom: "20px" }}>
//                 <Typography variant="h6" gutterBottom>
//                   {new Date(plan.date).toLocaleDateString()}{" "}
//                 </Typography>
//                 <Grid container spacing={3}>
//                   {/* Outfit Card */}
//                   {outfit && (
//                     <Grid item xs={12} sm={6} md={4}>
//                       <Card className="planner-card">
//                         <CardContent>
//                           <Typography variant="body1" component="p">
//                             {outfit.name || "Outfit"}
//                           </Typography>
//                           <img
//                             src={outfit.image}
//                             alt={outfit.name || "Outfit"}
//                             className="planner-image"
//                           />
//                         </CardContent>
//                       </Card>
//                     </Grid>
//                   )}
//                   {/* wardrobeP Card */}
//                   {wardrobeP && (
//                     <Grid item xs={12} sm={6} md={4}>
//                       <Card className="planner-card">
//                         <CardContent>
//                           <Typography variant="body1" component="p">
//                             {wardrobeP.name || "wardrobeP"}
//                           </Typography>
//                           <img
//                             src={wardrobeP.image}
//                             alt={wardrobeP.name || "wardrobeP"}
//                             className="planner-image"
//                           />
//                         </CardContent>
//                       </Card>
//                     </Grid>
//                   )}
//                 </Grid>
//               </Box>
//             );
//           })
//         ) : (
//           <Typography variant="h6" align="center">
//             No planner data available.
//           </Typography>
//         )}
//       </Box>
//     </Container>
//   );
// };

// const mapDispatchToProps = (dispatch) =>
//   bindActionCreators(
//     {
//       logoutRequest: actions.auth.logout,
//       startLoadingRequest: actions.loader.startLoading,
//       stopLoadingRequest: actions.loader.stopLoading,
//     },
//     dispatch
//   );

// export default connect(null, mapDispatchToProps)(Planner);

// import React, { useEffect, useState, useCallback } from "react";
// import { getUserById } from "../../apis";
// import { useParams } from "react-router-dom";
// import { bindActionCreators } from "redux";
// import { connect } from "react-redux";
// import actions from "../../actions";
// import "./planner.css";

// const PlannerPage = ({ startLoadingRequest, stopLoadingRequest }) => {
//   const [planners, setPlanners] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const { id } = useParams();

//   const fetchPlannerData = useCallback(async () => {
//     try {
//       startLoadingRequest();
//       const response = await getUserById(id);
//       const { planners } = response.data.data;
//       setPlanners(planners || []);
//       stopLoadingRequest();
//       setLoading(false);
//     } catch (error) {
//       console.error("Error fetching planner data:", error);
//       setPlanners([]);
//       stopLoadingRequest();
//       setLoading(false);
//     }
//   }, [startLoadingRequest, stopLoadingRequest, id]);

//   useEffect(() => {
//     fetchPlannerData();
//   }, [fetchPlannerData]);

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   const plannersByDate = planners.reduce((acc, planner) => {
//     const date = planner.date;
//     if (!acc[date]) {
//       acc[date] = [];
//     }
//     acc[date].push(planner);
//     return acc;
//   }, {});

//   return (
//     <div className="planner-page">
//       <h1>Planners</h1>
//       {Object.keys(plannersByDate).length === 0 ? (
//         <p className="no-items-message">No planners found</p>
//       ) : (
//         Object.keys(plannersByDate).map((date) => (
//           <div key={date}>
//             <h2 className="planner-date-heading">{date}</h2>
//             {plannersByDate[date].map((planner) => (
//               <div className="planner-card" key={planner._id}>
//                 <h3 className="planner-title">
//                   {planner.name || "Untitled Planner"}
//                 </h3>
//                 {planner.wardrobeId.length > 0 ? (
//                   <div className="wardrobeP-container">
//                     {planner.wardrobeId.map((item) => (
//                       <div className="wardrobeP-item" key={item._id}>
//                         <img
//                           src={item.image}
//                           alt={item.name}
//                           className="wardrobeP-image"
//                         />
//                         <p className="wardrobeP-name">{item.name}</p>
//                       </div>
//                     ))}
//                   </div>
//                 ) : (
//                   <p className="no-outfits-message">No outfits available</p>
//                 )}
//               </div>
//             ))}
//             <hr className="divider-line" />
//           </div>
//         ))
//       )}
//     </div>
//   );
// };

// const mapDispatchToProps = (dispatch) =>
//   bindActionCreators(
//     {
//       stopLoadingRequest: actions.loader.stopLoading,
//       startLoadingRequest: actions.loader.startLoading,
//     },
//     dispatch
//   );

// export default connect(null, mapDispatchToProps)(PlannerPage);
import React, { useEffect, useState, useCallback } from "react";
import { getUserById } from "../../apis";
import { useParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import actions from "../../actions";
import "./planner.css";

const PlannerPage = ({ startLoadingRequest, stopLoadingRequest }) => {
  const [planners, setPlanners] = useState([]);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  const fetchPlannerData = useCallback(async () => {
    try {
      startLoadingRequest();
      const response = await getUserById(id);
      const { planners } = response.data.data;
      setPlanners(planners || []);
      stopLoadingRequest();
      setLoading(false);
    } catch (error) {
      console.error("Error fetching planner data:", error);
      setPlanners([]);
      stopLoadingRequest();
      setLoading(false);
    }
  }, [startLoadingRequest, stopLoadingRequest, id]);

  useEffect(() => {
    fetchPlannerData();
  }, [fetchPlannerData]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const plannersByDate = planners.reduce((acc, planner) => {
    const date = planner.date;
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(planner);
    return acc;
  }, {});

  return (
    <div className="planner-page">
      <h1>Planners</h1>
      {Object.keys(plannersByDate).length === 0 ? (
        <p className="no-items-message">No planners found</p>
      ) : (
        Object.keys(plannersByDate).map((date) => (
          <div key={date}>
            <h2 className="planner-date-heading">Date: {date}</h2>
            {plannersByDate[date].map((planner) => (
              <div className="planner-card" key={planner._id}>
                <h3 className="planner-title">
                  {planner.name || "Untitled Planner"}
                </h3>
                {planner.outfitId ? (
                  <div className="outfit-container">
                    <strong>Outfit:</strong>
                    <div className="outfit-item">
                      <img
                        src={planner.outfitId.image}
                        alt={planner.outfitId.name}
                        className="outfit-image"
                      />
                      <p className="outfit-name">{planner.outfitId.name}</p>
                    </div>
                  </div>
                ) : (
                  <p className="no-outfits-message">No outfits available</p>
                )}
                {planner.wardrobeId.length > 0 ? (
                  <div className="wardrobeP-container">
                    <strong>Wardrobe:</strong>
                    {planner.wardrobeId.map((item) => (
                      <div className="wardrobeP-item" key={item._id}>
                        <img
                          src={item.image}
                          alt={item.name}
                          className="wardrobeP-image"
                        />
                        <p className="wardrobeP-name">{item.name}</p>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p className="no-outfits-message">
                    No wardrobe items available
                  </p>
                )}
              </div>
            ))}
            <hr className="divider-line" />
          </div>
        ))
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      stopLoadingRequest: actions.loader.stopLoading,
      startLoadingRequest: actions.loader.startLoading,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(PlannerPage);
