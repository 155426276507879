// // import React, { useState, useEffect, useCallback } from "react";
// // import { toast } from "react-toastify";
// // import { RxCrossCircled } from "react-icons/rx/index.esm";
// // import { Link, useLocation, useNavigate } from "react-router-dom";
// // import {
// //   getUsers,
// //   activateDeactivateUser,
// //   getAllUser,
// //   deactivateNormalUser,
// // } from "../../apis";
// // import { bindActionCreators } from "redux";
// // import { connect } from "react-redux";
// // import actions from "../../actions";
// // import Pagination from "../../components/Pagination/Pagination";

// // const Users = ({ logoutRequest, startLoadingRequest, stopLoadingRequest }) => {
// //   const navigate = useNavigate();
// //   const [data, setData] = useState([]);
// //   const [searchValue, setSearchValue] = useState("");
// //   const [page, setPage] = useState(1);
// //   const [total, setTotal] = useState(0);
// //   const { pathname, search } = useLocation();

// //   const fetch = useCallback(async () => {
// //     try {
// //       startLoadingRequest();
// //       const apiResponse = await getAllUser();
// //       if (apiResponse?.data?.data?.length > 0) {
// //         console.log(apiResponse.data.data);
// //         setData(apiResponse.data.data);
// //         setTotal(apiResponse.data.data.length);
// //       } else {
// //         setData([]);
// //       }
// //       stopLoadingRequest();
// //     } catch (error) {
// //       stopLoadingRequest();
// //       if (error?.response?.status === 401) {
// //         toast.error(error.response.data.message);
// //         logoutRequest();
// //       } else if (error?.response?.status === 403) {
// //         toast.error(error.response.data.message);
// //       }
// //     }
// //   }, [logoutRequest, startLoadingRequest, stopLoadingRequest]);

// //   useEffect(() => {
// //     fetch();
// //     if (search) {
// //       setSearchValue(
// //         search
// //           ?.split("?")[1]
// //           ?.split("&")
// //           ?.filter((el) => el.includes("search"))[0]
// //           ?.split("=")[1] || ""
// //       );
// //     }
// //   }, [fetch, search]);

// //   // const activateDeactivateUserHandler = useCallback(
// //   //   async (id, requestType) => {
// //   //     try {
// //   //       startLoadingRequest();
// //   //       await deactivateNormalUser({ userId: id ,status:}, { requestType });
// //   //       fetch();
// //   //       toast.success(
// //   //         requestType === 1
// //   //           ? "User activated successfully"
// //   //           : "User deactivated successfully"
// //   //       );
// //   //     } catch (error) {
// //   //       stopLoadingRequest();
// //   //       if (error?.response?.status === 401) {
// //   //         toast.error(error.response.data.message);
// //   //         logoutRequest();
// //   //       } else if (error?.response?.status === 403) {
// //   //         toast.error(error.response.data.message);
// //   //       }
// //   //     }
// //   //   },
// //   //   [fetch, logoutRequest, startLoadingRequest, stopLoadingRequest]
// //   // );

// //   // const activateDeactivateUserHandler = useCallback(
// //   //   async (id, requestType) => {
// //   //     try {
// //   //       startLoadingRequest();
// //   //       const status = requestType === 1 ? true : false; // true for activate, false for deactivate
// //   //       await deactivateNormalUser({ userId: id, status: status }); // Send the appropriate status
// //   //       fetch();
// //   //       toast.success(
// //   //         requestType === 1
// //   //           ? "User activated successfully"
// //   //           : "User deactivated successfully"
// //   //       );
// //   //     } catch (error) {
// //   //       stopLoadingRequest();
// //   //       if (error?.response?.status === 401) {
// //   //         toast.error(error.response.data.message);
// //   //         logoutRequest();
// //   //       } else if (error?.response?.status === 403) {
// //   //         toast.error(error.response.data.message);
// //   //       }
// //   //     }
// //   //   },
// //   //   [fetch, logoutRequest, startLoadingRequest, stopLoadingRequest]
// //   // );

// //   const activateDeactivateUserHandler = useCallback(
// //     async (id, requestType) => {
// //       try {
// //         startLoadingRequest();
// //         const status = requestType === 1 ? true : false; // Determine status based on requestType
// //         await deactivateNormalUser({ userId: id, status: status }); // Send userId and status in the request body
// //         fetch(); // Refresh the data after the operation
// //         toast.success(
// //           requestType === 1
// //             ? "User activated successfully"
// //             : "User deactivated successfully"
// //         );
// //       } catch (error) {
// //         stopLoadingRequest();
// //         if (error?.response?.status === 401) {
// //           toast.error(error.response.data.message);
// //           logoutRequest();
// //         } else if (error?.response?.status === 403) {
// //           toast.error(error.response.data.message);
// //         }
// //       }
// //     },
// //     [fetch, logoutRequest, startLoadingRequest, stopLoadingRequest]
// //   );

// //   const searchHandler = useCallback(() => {
// //     navigate(`/users?page=1&search=${searchValue?.trim()}`);
// //   }, [searchValue, navigate]);

// //   const searchChangeHandler = useCallback((event) => {
// //     setSearchValue(event.target.value);
// //   }, []);

// //   const resetSearchHandler = useCallback(() => {
// //     setSearchValue("");
// //   }, []);

// //   useEffect(() => {
// //     const timeout = setTimeout(() => {
// //       searchHandler();
// //     }, 1000);
// //     return () => clearTimeout(timeout);
// //   }, [searchValue, searchHandler]);

// //   const handleRowClick = (id) => {
// //     navigate(`/users/${id}`);
// //   };

// //   return (
// //     <div className="section">
// //       <h1>Users</h1>
// //       <div className="wrapper">
// //         <div className="table-wrapper">
// //           <div className="table-header">
// //             <div className="search">
// //               <div className="input-wrapper">
// //                 <input
// //                   value={searchValue}
// //                   onChange={searchChangeHandler}
// //                   placeholder="Search..."
// //                 />
// //                 {searchValue?.trim() && (
// //                   <RxCrossCircled onClick={resetSearchHandler} size={18} />
// //                 )}
// //               </div>
// //             </div>
// //           </div>
// //           <div className="table-container">
// //             <table>
// //               <thead>
// //                 <tr>
// //                   <th>#</th>
// //                   <th>Onboarding date</th>
// //                   <th>Name</th>
// //                   <th>Phone number</th>
// //                   <th>Email</th>
// //                   <th>Active/Inactive</th>
// //                   <th>Actions</th>
// //                 </tr>
// //               </thead>
// //               <tbody>
// //                 {data.map((item, index) => (
// //                   <tr key={item._id} onClick={() => handleRowClick(item._id)}>
// //                     <td>{index + 1}</td>
// //                     <td>{new Date(item.createdAt).toLocaleDateString()}</td>
// //                     {/* <td>{`${item.firstName} ${item.lastName}`}</td>
// //                      */}
// //                     <td>
// //                       <Link to={`/user/${item._id}`} className="no-link-style">
// //                         {`${item.firstName || "N/A"} ${item.lastName || ""}`}
// //                       </Link>
// //                     </td>
// //                     <td>{item.phoneNumber || "N/A"}</td>
// //                     <td>{item.email}</td>
// //                     <td>{item.isActive ? "Active" : "Inactive"}</td>
// //                     <td>
// //                       <div className="table-actions">
// //                         {item.isActive ? (
// //                           <button
// //                             className="danger"
// //                             onClick={(e) => {
// //                               e.stopPropagation();
// //                               activateDeactivateUserHandler(item._id, 0);
// //                             }}
// //                           >
// //                             Deactivate
// //                           </button>
// //                         ) : (
// //                           <button
// //                             onClick={(e) => {
// //                               e.stopPropagation();
// //                               activateDeactivateUserHandler(item._id, 1);
// //                             }}
// //                             className="success"
// //                           >
// //                             Activate
// //                           </button>
// //                         )}
// //                       </div>
// //                     </td>
// //                   </tr>
// //                 ))}
// //               </tbody>
// //             </table>
// //           </div>
// //           {data?.length > 0 && (
// //             <Pagination
// //               pageInfo={{
// //                 page: page,
// //                 total: total,
// //               }}
// //               onPageChange={(newPage) => setPage(newPage)} // Update page state
// //             />
// //           )}
// //         </div>
// //       </div>
// //     </div>
// //   );
// // };

// // const mapDispatchToProps = (dispatch) =>
// //   bindActionCreators(
// //     {
// //       logoutRequest: actions.auth.logout,
// //       stopLoadingRequest: actions.loader.stopLoading,
// //       startLoadingRequest: actions.loader.startLoading,
// //     },
// //     dispatch
// //   );

// // export default connect(null, mapDispatchToProps)(Users);

// import React, { useState, useEffect, useCallback } from "react";
// import { toast } from "react-toastify";
// import { RxCrossCircled } from "react-icons/rx/index.esm";
// import { Link, useLocation, useNavigate } from "react-router-dom";
// import { getAllUser, deactivateNormalUser, getUserById } from "../../apis";
// import { bindActionCreators } from "redux";
// import { connect } from "react-redux";
// import actions from "../../actions";
// import Pagination from "../../components/Pagination/Pagination";

// const Users = ({ logoutRequest, startLoadingRequest, stopLoadingRequest }) => {
//   const navigate = useNavigate();
//   const [data, setData] = useState([]);
//   const [searchValue, setSearchValue] = useState("");
//   const [page, setPage] = useState(1);
//   const [total, setTotal] = useState(0);
//   const { search } = useLocation();

//   const fetch = useCallback(async () => {
//     try {
//       startLoadingRequest();
//       const apiResponse = await getAllUser();
//       if (apiResponse?.data?.data?.users?.length > 0) {
//         setData(apiResponse.data.data.users);
//         setTotal(apiResponse.data.data.totalUsers);
//       } else {
//         setData([]);
//       }
//       stopLoadingRequest();
//     } catch (error) {
//       stopLoadingRequest();
//       if (error?.response?.status === 401) {
//         toast.error(error.response.data.message);
//         logoutRequest();
//       } else if (error?.response?.status === 403) {
//         toast.error(error.response.data.message);
//       }
//     }
//   }, [logoutRequest, startLoadingRequest, stopLoadingRequest]);

//   useEffect(() => {
//     fetch();
//     if (search) {
//       setSearchValue(
//         search
//           ?.split("?")[1]
//           ?.split("&")
//           ?.filter((el) => el.includes("search"))[0]
//           ?.split("=")[1] || ""
//       );
//     }
//   }, [fetch, search]);

//   // const activateDeactivateUserHandler = useCallback(
//   //   async (id, requestType) => {
//   //     try {
//   //       startLoadingRequest();
//   //       const status = requestType === 1 ? true : false;
//   //       await deactivateNormalUser({ userId: id, status: status });
//   //       fetch();
//   //       toast.success(
//   //         requestType === 1
//   //           ? "User activated successfully"
//   //           : "User deactivated successfully"
//   //       );
//   //     } catch (error) {
//   //       stopLoadingRequest();
//   //       if (error?.response?.status === 401) {
//   //         toast.error(error.response.data.message);
//   //         logoutRequest();
//   //       } else if (error?.response?.status === 403) {
//   //         toast.error(error.response.data.message);
//   //       }
//   //     }
//   //   },
//   //   [fetch, logoutRequest, startLoadingRequest, stopLoadingRequest]
//   // );
//   const activateDeactivateUserHandler = useCallback(
//     async (id, requestType) => {
//       try {
//         startLoadingRequest();
//         const status = requestType === 1 ? true : false;
//         await deactivateNormalUser({ userId: id, status: status });

//         // Update the user's isActive status in the data state
//         setData((prevData) =>
//           prevData.map((user) =>
//             user._id === id ? { ...user, isActive: status } : user
//           )
//         );

//         toast.success(
//           status
//             ? "User activated successfully"
//             : "User deactivated successfully"
//         );
//       } catch (error) {
//         stopLoadingRequest();
//         if (error?.response?.status === 401) {
//           toast.error(error.response.data.message);
//           logoutRequest();
//         } else if (error?.response?.status === 403) {
//           toast.error(error.response.data.message);
//         }
//       } finally {
//         stopLoadingRequest();
//       }
//     },
//     [logoutRequest, startLoadingRequest, stopLoadingRequest]
//   );

//   const searchHandler = useCallback(() => {
//     navigate(`/users?page=1&search=${searchValue?.trim()}`);
//   }, [searchValue, navigate]);

//   const searchChangeHandler = useCallback((event) => {
//     setSearchValue(event.target.value);
//   }, []);

//   const resetSearchHandler = useCallback(() => {
//     setSearchValue("");
//   }, []);

//   useEffect(() => {
//     const timeout = setTimeout(() => {
//       searchHandler();
//     }, 1000);
//     return () => clearTimeout(timeout);
//   }, [searchValue, searchHandler]);

//   const handleRowClick = (id) => {
//     navigate(`/users/${id}`);
//   };

//   return (
//     <div className="section">
//       <h1>Users</h1>
//       <div className="wrapper">
//         <div className="table-wrapper">
//           <div className="table-header">
//             <div className="search">
//               <div className="input-wrapper">
//                 <input
//                   value={searchValue}
//                   onChange={searchChangeHandler}
//                   placeholder="Search..."
//                 />
//                 {searchValue?.trim() && (
//                   <RxCrossCircled onClick={resetSearchHandler} size={18} />
//                 )}
//               </div>
//             </div>
//           </div>
//           <div className="table-container">
//             <table>
//               <thead>
//                 <tr>
//                   <th>#</th>
//                   <th>Onboarding date</th>
//                   <th>Name</th>
//                   <th>Phone number</th>
//                   <th>Email</th>
//                   <th>Active/Inactive</th>
//                   <th>Actions</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {data.map((item, index) => (
//                   <tr key={item._id} onClick={() => handleRowClick(item._id)}>
//                     <td>{index + 1}</td>
//                     <td>{new Date(item.createdAt).toLocaleDateString()}</td>
//                     <td>
//                       <Link to={`/user/${item._id}`} className="no-link-style">
//                         {`${item.firstName || "N/A"} ${item.lastName || ""}`}
//                       </Link>
//                     </td>
//                     <td>{item.phoneNumber || "N/A"}</td>
//                     <td>{item.email}</td>
//                     <td>{item.isActive ? "Active" : "Inactive"}</td>
//                     <td>
//                       <div className="table-actions">
//                         {item.isActive ? (
//                           <button
//                             className="danger"
//                             onClick={(e) => {
//                               e.stopPropagation();
//                               activateDeactivateUserHandler(item._id, 0);
//                             }}
//                           >
//                             Deactivate
//                           </button>
//                         ) : (
//                           <button
//                             onClick={(e) => {
//                               e.stopPropagation();
//                               activateDeactivateUserHandler(item._id, 1);
//                             }}
//                             className="success"
//                           >
//                             Activate
//                           </button>
//                         )}
//                       </div>
//                     </td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           </div>
//           {data?.length > 0 && (
//             <Pagination
//               pageInfo={{
//                 page: page,
//                 total: total,
//               }}
//               onPageChange={(newPage) => setPage(newPage)}
//             />
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// const mapDispatchToProps = (dispatch) =>
//   bindActionCreators(
//     {
//       logoutRequest: actions.auth.logout,
//       stopLoadingRequest: actions.loader.stopLoading,
//       startLoadingRequest: actions.loader.startLoading,
//     },
//     dispatch
//   );

// export default connect(null, mapDispatchToProps)(Users);

import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import { RxCrossCircled } from "react-icons/rx/index.esm";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getAllUser, deactivateNormalUser, getUserById } from "../../apis";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import actions from "../../actions";
import Pagination from "../../components/Pagination/Pagination";

const Users = ({ logoutRequest, startLoadingRequest, stopLoadingRequest }) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const { search } = useLocation();

  const fetch = useCallback(async () => {
    try {
      startLoadingRequest();
      const apiResponse = await getAllUser(page); // Pass the current page to the API call
      if (apiResponse?.data?.data?.users?.length > 0) {
        setData(apiResponse.data.data.users);
        setTotal(apiResponse.data.data.totalUsers);
      } else {
        setData([]);
      }
      stopLoadingRequest();
    } catch (error) {
      stopLoadingRequest();
      if (error?.response?.status === 401) {
        toast.error(error.response.data.message);
        logoutRequest();
      } else if (error?.response?.status === 403) {
        toast.error(error.response.data.message);
      }
    }
  }, [logoutRequest, startLoadingRequest, stopLoadingRequest, page]);

  useEffect(() => {
    fetch();
    if (search) {
      setSearchValue(
        search
          ?.split("?")[1]
          ?.split("&")
          ?.filter((el) => el.includes("search"))[0]
          ?.split("=")[1] || ""
      );
    }
  }, [fetch, search]);

  const activateDeactivateUserHandler = useCallback(
    async (id, requestType) => {
      try {
        startLoadingRequest();
        const status = requestType === 1 ? true : false;
        await deactivateNormalUser({ userId: id, status: status });

        setData((prevData) =>
          prevData.map((user) =>
            user._id === id ? { ...user, isActive: status } : user
          )
        );

        toast.success(
          status
            ? "User activated successfully"
            : "User deactivated successfully"
        );
      } catch (error) {
        stopLoadingRequest();
        if (error?.response?.status === 401) {
          toast.error(error.response.data.message);
          logoutRequest();
        } else if (error?.response?.status === 403) {
          toast.error(error.response.data.message);
        }
      } finally {
        stopLoadingRequest();
      }
    },
    [logoutRequest, startLoadingRequest, stopLoadingRequest]
  );

  const searchHandler = useCallback(() => {
    navigate(`/users?page=1&search=${searchValue?.trim()}`);
  }, [searchValue, navigate]);

  const searchChangeHandler = useCallback((event) => {
    setSearchValue(event.target.value);
  }, []);

  const resetSearchHandler = useCallback(() => {
    setSearchValue("");
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      searchHandler();
    }, 1000);
    return () => clearTimeout(timeout);
  }, [searchValue, searchHandler]);

  const handleRowClick = (id) => {
    navigate(`/users/${id}`);
  };

  return (
    <div className="section">
      <h1>Users</h1>
      <div className="wrapper">
        <div className="table-wrapper">
          <div className="table-header">
            <div className="search">
              <div className="input-wrapper">
                <input
                  value={searchValue}
                  onChange={searchChangeHandler}
                  placeholder="Search..."
                />
                {searchValue?.trim() && (
                  <RxCrossCircled onClick={resetSearchHandler} size={18} />
                )}
              </div>
            </div>
          </div>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Onboarding date</th>
                  <th>Name</th>
                  <th>Phone number</th>
                  <th>Email</th>
                  <th>Active/Inactive</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr key={item._id} onClick={() => handleRowClick(item._id)}>
                    <td>{index + 1}</td>
                    <td>{new Date(item.createdAt).toLocaleDateString()}</td>
                    <td>
                      <Link to={`/user/${item._id}`} className="no-link-style">
                        {`${item.firstName || "N/A"} ${item.lastName || ""}`}
                      </Link>
                    </td>
                    <td>{item.phoneNumber || "N/A"}</td>
                    <td>{item.email}</td>
                    <td>{item.isActive ? "Active" : "Inactive"}</td>
                    <td>
                      <div className="table-actions">
                        {item.isActive ? (
                          <button
                            className="danger"
                            onClick={(e) => {
                              e.stopPropagation();
                              activateDeactivateUserHandler(item._id, 0);
                            }}
                          >
                            Deactivate
                          </button>
                        ) : (
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              activateDeactivateUserHandler(item._id, 1);
                            }}
                            className="success"
                          >
                            Activate
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {data?.length > 0 && (
            <Pagination
              pageInfo={{
                page: page,
                total: total,
              }}
              onPageChange={(newPage) => setPage(newPage)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logoutRequest: actions.auth.logout,
      stopLoadingRequest: actions.loader.stopLoading,
      startLoadingRequest: actions.loader.startLoading,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(Users);
